import React, {useRef, useState, useEffect} from 'react';

import Select from 'react-select';
import {Handle} from 'reactflow';

import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import {ckEditorBasic} from '../../config';
import {ColorPicker} from '../ColorPicker';
import {User} from '../../states/actions';
//import Buttons from 'react-multi-date-picker/components/button';

import {validations, moneyCurrency, ckEditorInputBlock} from '../../config';
import {InputComponent, SelectComp, ButtonState, onEdited, ButtonSwitch} from './Components'

import {DecisionEditor} from './DecisionBlock'
import {CalculationEditor} from './CalculationBlock'
import {ConditionEditor} from './ConditionBlock'
import {MultiplechoiceEditor} from './MultipleChoiceBlock'
import {ReviewEditor} from './ReviewBlock'
import {PaymentEditor} from './PaymentBlock'
import {DataEditor} from './DataBlock'
import {DocumentEditor} from './DocumentBlock'
import {EmailEditor} from './EmailBlock'
import {ShareEditor} from './ShareBlock'
import {UploadEditor} from './UploadBlock'
import {TeleportEditor} from './TeleportBlock'
import {SheetEditor} from './SheetBlock'
import {MatchEditor} from './MatchBlock'
import {ScoroEditor} from './ScoroBlock'
import {AiEditor} from './AiBlock'
import {LoginEditor} from './LoginBlock'
import {CalendarEditor} from './CalendarBlock'
import {ProjectEditor} from './ProjectBlock'



export const BlockEditor = ({node, onUpdate}) => {

  const optionSelectRef = useRef(null);
  const bot = useSelector(Chatbot.get());
  const [routeSecondary, setRouteSecondary] = useState(false);
  const [language, setLanguage] = useState('default'); // Options: 'default', 'en', 'ru'


  const inputRef = React.useRef(null);
  const refTxtText = useRef(null);
  const refTxtValue = useRef(null);
  const [loaded, setLoaded] = useState(false);

  
  const block = Chatbot.getBlock(node);
  const {
    data,
    data: {decisions = {}, options ={}, reviews={}},
  } = block;


  const [contentvalue, setContentValue] = useState(data.content);
  const [notificationvalue, setNotificationValue] = useState(data.notification);
  const [modalvalue, setModalValue] = useState(data.modal);


  const [notification, showNotification] = useState(notificationvalue !== undefined && notificationvalue.length > 0 ? true : false);
  const [modal, showModal] = useState(data.modal !== undefined && data.modal.length > 0 ? true : false);


  useEffect(() => {
    console.log(language)
    const content = handleLocalizedValue('content');
    setContentValue(data[content]);
    const notification = handleLocalizedValue('notification');
    setNotificationValue(data[notification]);
    const modal = handleLocalizedValue('modal');
    setModalValue(data[modal]);

    showNotification(data[notification] !== undefined && data[notification].length > 0 ? true : false)
    showModal(data[modal] !== undefined && data[modal].length > 0 ? true : false)


  }, [language]); // Watching for language change



  const {_id} = useSelector(User.get());



  //Use this to give time to load components with new data. 
  //This is not the best approach.
  useEffect(() => {
    setTimeout(() => {
     setLoaded(true)
    }, "1")
   }, [block]);
  
  const variableOptions = Chatbot.getBlockVariables();

  let variables_inputtype = [
    {
      label: 'Input',
      value: 'input',
      content: 'input',
    },
    {
      label: 'Textarea',
      value: 'textarea',
      content: 'textarea',
    },
    {
      label: 'Signature',
      value: 'signature',
      content: 'signature',
    },
  ];


  if (!block) {
        return null;
  }



  // Handler to change language
  const handleLanguageChange = (newLanguage) => {
    console.log(newLanguage)
    setLanguage(newLanguage);
  };


 
 const handleBlur = (e) => {   
  //e.preventDefault();s
  //console.log("blur", e.target.name)
  //saveData(block.id, e.target.name, e.target.value, e.target);
  Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
 };

 const handleBlurContent = (data, content) => {   
  const content_local = handleLocalizedValue(content)
  Chatbot.setBlockData(block.id, {[content_local]: data});
 };

 
 const handleLocalizedValue = (value) => {   
  const value_local = language === 'en' ? value +`_en` : language === 'ru' ? value + `_ru` : value;
  return value_local
};


function EditorHeader() {
  return (
          <>
           <div className="editor-header">

           <i className=""><img src={'/images/icons/'+data.type+'.png'} alt="..." /></i> 
                  <span> {data.type}</span>  
              <span className='float-right'>

              
          
              {(block.type === "inputBlock" || block.type === "textBlock" || block.type === "decisionBlock" || block.type === "multipleChoiceBlock" || block.type === "paymentBlock" || block.type === "uploadBlock" || block.type === "loginBlock" || block.type === "matchBlock" || block.type === "emailBlock" || block.type === "calendarBlock" || block.type === "aiBlock" || block.type === "documentBlock" || block.type === "scoroBlock")  &&(

                  <>
                    <i className={`mx-1 btn-circle ${language === 'default' ? 'active' : ''}`}  onClick={() => handleLanguageChange('default')}> et </i>
                    <i className={`mx-1 btn-circle ${language === 'en' ? 'active' : ''}`}  onClick={() => handleLanguageChange('en')}> en </i>
                    <i className={`mx-1 btn-circle ${language === 'ru' ? 'active' : ''}`}  onClick={() => handleLanguageChange('ru')}> ru </i>
                  </>

              )}

              <i className="mx-1 badge bg-success" onClick={() => {onSetStart(node); onEdited()}}>
                Set home
              </i>
             {/*  <i className="mx-1 badge bg-danger" onClick={() => onRemove(node)}>
                Remove
              </i>

              <i
                className="ms-1 me-2 icon fa fa-times"
                onClick={() => onClose(node)}
              ></i> */}
              </span>
              </div>
          </>
  );
}

/* function Button() {
  return (
          <>

          <InputComponent data={data} name="Button name" dataname="buttontext" onBlur={handleBlur}/>

          <InputComponent data={data} name="Validation Message" dataname="validationMessage" onBlur={handleBlur}/>

          {block.type === 'textBlock' ? (
              <Buttonstate/>
              ):(<></>)}
          </>
  );
} */
/* 
function Buttonstate(block, label) {    

    //const [buttonstate, setButtonState] = useState(data[dataname]);

    function setActive(e){
      const state = buttonstate ? false : true;
      setButtonState(state); 
      Chatbot.setBlockData(block.id, {button: state}) 
   }

  return (
      <>


        <div className='btn-switch' onClick={(e) => {setActive(e)}}>
              <div  
                    className={`switch ${buttonstate ? 'on' : 'off'}`}>
                    <span></span>
               </div>
              <span className='text'>{label}</span>
        </div>
       
      </> 
  )
} 
*/

/* Not using atm because translation management needs to update content of modal, but when using component then content does not updated correclty yet. TODO later.*/
function Modal() {   

  if(data.modal !== undefined){
    if(data.modal.length > 0){showModal(true)} 
  }
  return (
    <>
    
    {!modal &&
    <div className="mb-2 mt-2 pointer link" onClick={()=> {showModal(modal ? false : true, modal ? true : false)}}> + Modal</div>  
    }
    {modal && (
      <>
        <br/>
        <div>

        <InputComponent data={data} name="Modal" placeholder="Link name" dataname={ language === 'en' ? `modallink_en` : language === 'ru' ? `modallink_ru` : `modallink`} language={language}  onBlur={handleBlur}/>

         <div id="modal">
            <CKEditor
              config={ckEditorBasic}
              editor={ClassicEditor}
              onChange={onEdited}
              data={modalvalue}
              onBlur={(e, editor) => {
                handleBlurContent(editor.getData(), 'modal');
              }}

            />
          </div>
          </div>
      </> 
      )}
      </>
  )
}



const SecondButton = () => {

  if(data.secondbutton !== undefined){
    if(data.secondbutton.length > 0){setRouteSecondary(true)} 
  } 
  return (
    <>
    {!routeSecondary && 
      <div className="mb-2 mt-2 pointer link" onClick={()=> {
        const state = routeSecondary ? false : true ? true : false;
        setRouteSecondary(state)
        if(!state){
        Chatbot.setBlockData(block.id, {next_secondary : ""})
        }
      }
      }> + Add Route</div>
    }
   
    </> 
  )
}


  





return (

       <>
            
              <EditorHeader/>
              {loaded &&(
              <div className='content'>

                 

                  {/* message */}
                  {(block.type === "commentBlock") && (
                          <div className="mb-2">
                          <label className="form-label comp-header">
                           Comment<i></i>
                          </label>
                        <div id="content">
                          <CKEditor
                            onReady={(editor) => {
                              /* editor.editing.view.change((writer) => {
                                writer.setStyle('height', '100px', editor.editing.view.document.getRoot());
                              }); */
                            }}
                            config={
                              {
                                toolbar: ['bold', 'italic', 'link', 'ctaButton', 'insertImage'],
                                link: {
                                  // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                                  addTargetToExternalLinks: true,
                                },
                              simpleUpload: {
                                uploadUrl: `${process.env.REACT_APP_BASE_URL}/chat/image/${_id}`,
                              }
                              }
                          }
                            onChange={onEdited}
                            editor={ClassicEditor}
                            data={data.content}
                            onBlur={(e, editor) => {
                              Chatbot.setBlockData(block.id, {content: editor.getData()});
                            }}
                          />
                        </div>
                          
                      </div>
                  )}

                  {(block.type === "inputBlock" || block.type === "textBlock" || block.type === "decisionBlock" || block.type === "multipleChoiceBlock" || block.type === "reviewBlock" || block.type === "paymentBlock" || block.type === "shareBlock" || block.type === "uploadBlock" || block.type === "loginBlock" || block.type === "matchBlock")  &&(
                        
                        <>
                        <br/> <br/>


                        <InputComponent data={data} name="Message" dataname={ language === 'en' ? `headtext_en` : language === 'ru' ? `headtext_ru` : `headtext`} language={language} onBlur={handleBlur}/>
                         

                        {/* contnent message */}
                        <div>
                          <div id="content">
                            <CKEditor
                              onReady={(editor) => {
                                /* editor.editing.view.change((writer) => {
                                  writer.setStyle('height', '100px', editor.editing.view.document.getRoot());
                                }); */
                              }}
                              config={
                                {
                                  toolbar: ['bold', 'italic', 'link', 'ctaButton', 'insertImage'],
                                  link: {
                                    // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                                    addTargetToExternalLinks: true,
                                  },
                                simpleUpload: {
                                  uploadUrl: `${process.env.REACT_APP_BASE_URL}/chat/image/${_id}`,
                                }
                                }
                            }
                              onChange={onEdited}
                              editor={ClassicEditor}
                              data={contentvalue}
                              onBlur={(e, editor) => {
                                handleBlurContent(editor.getData(), 'content');
                              }}
                            />
                          </div>
                            
                        </div>


                        {!notification &&
                        <div className="mb-2 mt-2 pointer link" onClick={()=> {showNotification(notification ? false : true, notification ? true : false)}}> + Notification</div>
                         } 
                         {notification && (
                              <>
                                  <br/>

                                <div>
                                        <label htmlFor="notification" className="form-label comp-header">
                                          Notification
                                        </label>
                                        <div id="notification">
                                          <CKEditor
                                            config={ckEditorBasic}
                                            editor={ClassicEditor}
                                            onChange={onEdited}

                                            data={notificationvalue}
                                            onBlur={(e, editor) => {
                                              handleBlurContent(editor.getData(), 'notification');
                                            }}

                                          />
                                        </div>
                                      </div>
                              </>
                        )} 
                          
    
                         {!modal &&
                        <div className="mb-2 mt-2 pointer link" onClick={()=> {showModal(modal ? false : true, modal ? true : false)}}> + Modal</div>  
                         }                      
                        {modal && (
                            <>
                              <br/>
                              <div>

                              <InputComponent data={data} name="Modal" placeholder="Link name" dataname={ language === 'en' ? `modallink_en` : language === 'ru' ? `modallink_ru` : `modallink`} language={language}  onBlur={handleBlur}/>

                              <div id="modal">
                                  <CKEditor
                                    config={ckEditorBasic}
                                    editor={ClassicEditor}
                                    onChange={onEdited}
                                    data={modalvalue}
                                    onBlur={(e, editor) => {
                                      handleBlurContent(editor.getData(), 'modal');
                                    }}

                                  />
                                </div>
                                </div>
                            </> 
                            )}
                                                  
      
                       {/*  <Modal/> */}
                        
                      </>
                  )}
                  




                  {block.type === "inputBlock" &&(
                    <>
                    <div className='section'>INPUT</div>

                    <SelectComp data={data} name="Input type" dataname="inputtype" options={variables_inputtype} block={block}/>
                    

                    {/* select input type */}
                   {/*  <ButtonSwitch name="Input type" block={block} data={data} dataname="inputtype" label1="" label2="Long input" state1="input" state2="textarea"/> */}

                   <InputComponent data={data} name="Character limit" dataname="characterlimit" onBlur={handleBlur}/>

                    <SelectComp data={data} name="Validation Rule" dataname="validation" placeholder="Select validation rule" options={validations} block={block}/>

                    {data.validation === 'custom' && (
                     <InputComponent data={data} name="Custom rule" dataname="customrule" onBlur={handleBlur}/>
                    )}

                    {data.validation === 'money' && (
                      <SelectComp data={data} name="Choose Currency" dataname="currency" placeholder="Choose currency" options={moneyCurrency} block={block}/>
                    )}

                    <br/><br/><br/>
                    
                    <InputComponent data={data} name="Input name" dataname={ language === 'en' ? `inputname_en` : language === 'ru' ? `inputname_ru` : `inputname`} language={language} onBlur={handleBlur}/>

                    <InputComponent data={data} name="Hint message" dataname={ language === 'en' ? `hint_en` : language === 'ru' ? `hint_ru` : `hint`} language={language} onBlur={handleBlur}/>


                    </>
                  )}

                  {block.type === "calculationBlock" &&(
                    <CalculationEditor nodeid={node} block={block} data={data}/>
                  )}

                  {block.type === "conditionBlock" &&(
                    <ConditionEditor nodeid={node} block={block} data={data} />
                  )}

                   {block.type === "dataBlock" &&(
                    <DataEditor nodeid={node} block={block} data={data} />
                  )}

                  {block.type === "teleportBlock" &&(
                    <TeleportEditor nodeid={node} block={block} data={data} reviews={reviews} />
                  )}
                  
                  {block.type === "decisionBlock" && language &&(
                    <DecisionEditor nodeid={node} block={block} data={data} decisions={decisions} language={language} />
                  )}

                  {block.type === "multipleChoiceBlock" &&(
                    <>
                      <InputComponent data={data} name="Input name" dataname={ language === 'en' ? `inputname_en` : language === 'ru' ? `inputname_ru` : `inputname`} language={language} onBlur={handleBlur}/>

                      <MultiplechoiceEditor nodeid={node} block={block} data={data} options={options} />
                    </>
                  )}

                  {block.type === "reviewBlock" &&(
                    <ReviewEditor nodeid={node} block={block} data={data} reviews={reviews} />
                  )}

                  {block.type === "shareBlock" &&(
                    <ShareEditor nodeid={node} block={block} data={data} reviews={reviews} />
                  )}
                  
                 {block.type === "uploadBlock" &&(
                    <UploadEditor nodeid={node} block={block} data={data} reviews={reviews} />
                  )}

                  {block.type === "paymentBlock" &&(
                    <PaymentEditor nodeid={node} block={block} data={data} variableoptions={variableOptions}/>
                  )}

                  {block.type === "scoroBlock" &&(
                    <ScoroEditor nodeid={node} block={block} data={data} variableoptions={variableOptions}/>
                  )}


                  {block.type === "documentBlock" &&(
                    <DocumentEditor nodeid={node} block={block} data={data}/>
                  )}


                  {block.type === "projectBlock" &&(
                    <ProjectEditor nodeid={node} block={block} data={data}/>
                  )}


                  {block.type === "sheetBlock" &&(
                    <SheetEditor nodeid={node} block={block} data={data}/>
                  )}

                  {block.type === "aiBlock" &&(
                    <AiEditor nodeid={node} block={block} data={data}/>
                  )}

                  {block.type === "matchBlock" &&(
                    <MatchEditor nodeid={node} block={block} data={data}/>
                  )}

                  {block.type === "loginBlock" &&(
                    <LoginEditor nodeid={node} block={block} data={data}/>
                  )}
                  
                  {block.type === "calendarBlock" &&(
                    <>
                    <br/><br/>
                    <InputComponent data={data} name="Message" dataname={ language === 'en' ? `headtext_en` : language === 'ru' ? `headtext_ru` : `headtext`} language={language} onBlur={handleBlur}/>

                    <CalendarEditor nodeid={node} block={block} data={data}/>
                    </>
                  )}
                   
                  {block.type === "emailBlock" &&(
                    <>
                    <br/><br/>

                      <InputComponent data={data} name="Subject" dataname={ language === 'en' ? `subject_en` : language === 'ru' ? `subject_ru` : `subject`} language={language} onBlur={handleBlur}/>
                         

                      {/* contnent message */}
                      <div>
                        <div id="content">
                          <CKEditor
                            onReady={(editor) => {
                              /* editor.editing.view.change((writer) => {
                                writer.setStyle('height', '100px', editor.editing.view.document.getRoot());
                              }); */
                            }}
                            config={
                              {
                                toolbar: ['bold', 'italic', 'link', 'ctaButton', 'insertImage'],
                                link: {
                                  // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                                  addTargetToExternalLinks: true,
                                },
                              simpleUpload: {
                                uploadUrl: `${process.env.REACT_APP_BASE_URL}/chat/image/${_id}`,
                              }
                              }
                          }
                            onChange={onEdited}
                            editor={ClassicEditor}
                            data={contentvalue}
                            onBlur={(e, editor) => {
                              handleBlurContent(editor.getData(), 'content');
                            }}
                          />
                        </div>
                          
                      </div>

                      <br/><br/>

                      <EmailEditor nodeid={node} block={block} data={data}/>

                     </>
                  )}

                  
                  {(block.type === "inputBlock" || block.type === "multipleChoiceBlock" || block.type === "uploadBlock" || block.type === "decisionBlock") &&(
                    
                    <InputComponent data={data} name="Validation message" dataname={ language === 'en' ? `validationMessage_en` : language === 'ru' ? `validationMessage_ru` : `validationMessage`} language={language} onBlur={handleBlur}/>

                  )}

                  {(block.type === "inputBlock" || block.type === "uploadBlock" || block.type === "paymentBlock" || block.type === "emailBlock" || block.type === "calendarBlock" || block.type === "matchBlock" || block.type === "aiBlock" || block.type === "documentBlock" || block.type === "scoroBlock") &&(
                     <InputComponent data={data} name="Waiting message" dataname={ language === 'en' ? `waitmessage_en` : language === 'ru' ? `waitmessage_ru` : `waitmessage`} language={language} onBlur={handleBlur}/>
                  )}



                  {/* BUTTON */}
                  {(block.type === "inputBlock" || block.type === "decisionBlock" && Object.values(decisions).length > 2 || block.type === "multipleChoiceBlock" || block.type === "textBlock" || block.type === "shareBlock" || block.type === "paymentBlock" || block.type === "reviewBlock" || block.type === "uploadBlock" || block.type === "calendarBlock" || block.type === "matchBlock") &&(
                    <>

                      <div className='section'>BUTTON</div>
                      <InputComponent data={data} name="Button name" language={language} dataname={ language === 'en' ? `buttontext_en` : language === 'ru' ? `buttontext_ru` : `buttontext`} onBlur={handleBlur}/>


                      {(block.type === "inputBlock" || block.type === "shareBlock" || block.type === "uploadBlock" || block.type === "calendarBlock" || block.type === "matchBlock") && (
                        <>
                          <SecondButton />

                          {routeSecondary && (
                            <>
                              <br />
                              <InputComponent data={data} name="Second route" language={language} dataname={ language === 'en' ? `secondbutton_en` : language === 'ru' ? `secondbutton_ru` : `secondbutton`} onBlur={handleBlur}/>
                            </>
                          )}
                        </>
                      )}


                  

                    

                      {block.type === 'textBlock' ? (
                        <>
                        <ButtonState block={block} data={data} startstate={true} dataname="button" label="Active"/>
                        <br/>
                        <ButtonState block={block} data={data} startstate={false} dataname="finish" label="END BLOCK"/>
                         </>
                      ):(<></>)}  
                       {block.type === 'paymentBlock' ? (
                        <>
                        <ButtonState block={block} data={data} startstate={false} dataname="finish" label="END"/>
                         </>
                      ):(<></>)}  

                    </>       
                  )}

                  {/* DATA */}
                  {(block.type === "inputBlock" || block.type === "decisionBlock" || block.type === "multipleChoiceBlock" || block.type === "shareBlock" || block.type === "uploadBlock" || block.type === "calculationBlock" || block.type === "aiBlock") &&(
                    <>
                      <div className='section'>DATA</div>
                      <InputComponent icon="*" data={data} name="Variable" dataname="variable" onBlur={handleBlur}/>
                    </>
                  )}


              </div>
              )}
              
            
                
                
              
          
    </>
    )

}