import React, {useRef, useState, useId, useEffect} from 'react';
import Select from 'react-select';
import {Chatbot} from '../../states/actions';
import {Handle, useStore} from 'reactflow';
import {onEdit} from '../../helpers/block-helper';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import {ckEditorBasic} from '../../config';


/* Using window object to store data for short user interaction processes.
Using this to load node editor only on clicking on certain node element.
--> so progress selection or any other extra function would not trigger
loading editor.
 */
const setNode = (node) => {
  window.el = node.id;
}
export const onEdited = (e) => {
  window.isEdited = true;
 /*  window.handleInput = e.target.value;
  console.log(e.target.value)
  console.log(window.isEdited) */
}

/* export const InputComponent = ({data, name, dataname, placeholder, onBlur, onLoad, icon}) => {  

    return (
      <>
      <div className="mb-2">
      <label htmlFor={dataname} className="form-label comp-header">
       {name}<i><b>{icon}</b></i>
      </label>
       <input
          type="input"
          id={dataname}
          name={dataname}
          className="form-control"
          placeholder={placeholder}
          defaultValue={data[dataname]}
          onBlur={onBlur}
          onLoad={onLoad}
          onChange={onEdited}
          />
      </div>
    </>
    );
} */

  export const InputComponent = ({
    data,
    name,
    dataname,
    placeholder,
    onBlur,
    onLoad,
    icon,
    language, // Added language prop
  }) => {

  const [value, setValue] = useState(data[dataname]);
  
  useEffect(() => {
    console.log(language, dataname)
    setValue(data[dataname]);
  }, [language]); // Watching for language change

  const handleChange = (e) => {
    setValue(e.target.value);
    console.log("set:" , e.target.value)
    if (onEdited) {
      onEdited(e);
    }
  };

  return (
    <div className="mb-2">
      <label htmlFor={dataname} className="form-label comp-header">
        {name}<i><b>{icon}</b></i>
      </label>
      <input
        type="input"
        id={dataname}
        name={dataname}
        value={value}
        className="form-control"
        placeholder={placeholder}
        defaultValue={data[dataname]}
        onBlur={onBlur}
        onLoad={onLoad}
        onChange={handleChange}
      />
    </div>
  );
};

export const ButtonState = ({block, data, dataname, label, startstate}) => {
  let state = "";
  switch (data[dataname]) {
    case true:
      state = true;
      break;
    case false:
      state = false;
      break;
    case undefined:
      state = startstate;
      break;
    default:
      state = true;
      break;
  }
  const [buttonstate, setButtonState] = useState(state);
  function setActive(e){
    console.log("THISISSTATE", state, startstate)

    state = buttonstate ? false : true;
    setButtonState(state); 
    Chatbot.setBlockData(block.id, {[dataname]: state}) 
    onEdited()
 }

return (
    <>
      {/* <div className="mb-2">
            <label htmlFor="button" className="form-label">
              Active
            </label>
            <input
              type="checkbox"
              id="button"
              name="button"
              className="ml-2"
              defaultChecked={data.button}
              onBlur={(e) => Chatbot.setBlockData(block.id, {[e.target.name]: e.target.checked})}
            />
      </div> */}

      <div className='btn-switch' onClick={(e) => {setActive(e)} } >
            <div  
                  className={`switch ${buttonstate ? 'on' : 'off'}`}>
                  <span></span>
             </div>
            <span className='text'>{label}</span>
      </div>
     
    </> 
)
}

export const ButtonSwitch = ({block, data, dataname, label1, state1, label2, state2, name}) => {
  let state = state1;
  switch (data[dataname]) {
    case state1:
      state = state1;
      break;
    case state2:
      state = state2;
      break;
    default:
      state = state1;
      break;
  }
  const [buttonstate, setButtonState] = useState(state);
  function setActive(e){
    state = buttonstate === state2 ? state1 : state2
    setButtonState(state); 
    Chatbot.setBlockData(block.id, {[dataname]: state}) 
    onEdited()
 }

return (
    <>
      {/* <div className="mb-2">
            <label htmlFor="button" className="form-label">
              Active
            </label>
            <input
              type="checkbox"
              id="button"
              name="button"
              className="ml-2"
              defaultChecked={data.button}
              onBlur={(e) => Chatbot.setBlockData(block.id, {[e.target.name]: e.target.checked})}
            />
      </div> */}

 

      <div className="mb-2">
      <label className="form-label comp-header">
       {name}
      </label>
      <div className='btn-switch' onClick={(e) => {setActive(e)}}>
           <span className='text'>{label1}</span>
            <div  
                  className={`switch ${buttonstate === state2 ? 'on' : 'off'}`}>
                  <span></span>
             </div>
            <span className='text'>{label2}</span>
      </div>

      </div>
     
    </> 
)
}

/* THIS HAS ISSUES in saving data, fixed? */
export const TextareaComponent = ({data, name, dataname, placeholder, rows, onBlur, defaultValue, icon}) => {
  return (
    <>
           <div className="mb-2">
                  <label htmlFor={dataname} className="form-label comp-header">
                    {name}<i><b>{icon}</b></i>
                  </label>
                  <textarea
                    id={dataname}
                    name={dataname}
                    defaultValue={defaultValue ? defaultValue : data[dataname]}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onChange={onEdited}
                    className="form-control"
                    rows={rows}
                  ></textarea>
      </div>  
  </>
  );
}

export const RichTextareaComponent = ({data, name, dataname, placeholder, rows, onBlur, defaultValue, icon, block}) => {
  return (
    <>
           <div className="mb-2">
                  <label htmlFor={dataname} className="form-label comp-header">
                    {name}<i><b>{icon}</b></i>
                  </label>
                 
                  <CKEditor
                    config={ckEditorBasic}
                    editor={ClassicEditor}
                    data={data[dataname]}
                    onChange={onEdited}
                    onBlur={(e, editor) => {
                      Chatbot.setBlockData(block.id, {[dataname]: editor.getData()});
                      /* if(data.notification.length < 1){showNotification(false)}  */
                    }}
                  />
      </div>  
  </>
  );
}


/* not in use */

export const SelectComponent = ({data, name, dataname, placeholder, options, block, onChange, onLoad, ref}) => {

  return (
    <>
              <div className="mb-2">
                  <label htmlFor={dataname} className="form-label comp-header">
                    {name}
                  </label>
                    <Select
                      className="form-control form-control-sm editor-select"
                      placeholder={placeholder}
                      id={dataname}
                      classNamePrefix="editor-select"
                      name={dataname}
                      ref={ref}
                      defaultValue = "input"
                      options={options}
                      onChange={onChange}
                      onLoad={onLoad}
                      value={options.find((item) => item.value === data[dataname])}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderRadius: 0,
                          border: 'none',
                        }),
                      }}
                    />
        
               </div>


    {/* <div className="mb-2">
                  <label htmlFor="validation" className="form-label">
                    Validation Rule
                  </label>
                  <Select
                    id="validation"
                    name="validation"
                    options={validations}
                    placeholder="Type"
                    value={validations.find((i) => i.value === data.validation)}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={(option) => {
                      Chatbot.setBlockData(block.id, {validation: option.value});
                    }}
                  />
                </div> */}
  </>
  );
}

export const SelectComp = ({data, name, dataname, dataname2, placeholder, options, block, value, value2, sort, multi = false}) => {



  return (
    <>
    


              {multi ? (
                 <> 
                   <div className="mb-2">
                   <label htmlFor="validation" className="form-label comp-header">
                     {name}
                   </label>
                  <Select
                    className="form-control form-control-sm editor-select"
                    classNamePrefix="editor-select"
                    placeholder={placeholder}
                    id={dataname}
                    name={dataname}
                    defaultValue="input"
                    options={options}
                    onChange={(selectedOptions) => {
                      Chatbot.setBlockData(block.id, {
                        assign: {...data, [dataname]: selectedOptions ? selectedOptions.map(option => option[value ? value : 'value']) : [], [dataname2]: selectedOptions ? selectedOptions.map(option => option[value ? value2 : 'value']) : []}
                      });
                      onEdited();
                    }}

                    /* Chatbot.setBlockData(block.id, {
                      assign: {...data, lawyers: option.map((i) => i.value), lawyerEmails: option.map((i) => i.email)},
                    }); */

                    value={options?.filter((option) => data[dataname]?.includes(option[value ? value : 'value']))}
                    //value={options?.filter(option => data[dataname].includes(option[value ? value : 'value']))}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            borderRadius: 0,
                            border: 'none',
                        }),
                    }}
                    isClearable={true}  // This prop enables the "x" clear icon
                    isMulti={multi}
                    required
                />
        
             </div>

              </>
            ) : (
              <>

              <div className="mb-2">
              <label htmlFor="validation" className="form-label comp-header">
                {name}
              </label>
                <Select
                className="form-control form-control-sm editor-select"
                classNamePrefix="editor-select"
                placeholder={placeholder}
                id={dataname}
                name={dataname}
                defaultValue="input"
                options={options}
                onChange={(option) => {
                    Chatbot.setBlockData(block.id, {[dataname]: option ? option[value ? value : 'value'] : ''});
                    onEdited();
                }}
                value={options.find((item) => item[value ? value : 'value'] === data[dataname])}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        borderRadius: 0,
                        border: 'none',
                    }),
                }}
                isClearable={true}  // This prop enables the "x" clear icon
                required
              />
            </div>

            </>
            
            )}

  
             

    {/* <div className="mb-2">
                  <label htmlFor="validation" className="form-label">
                    Validation Rule
                  </label>
                  <Select
                    id="validation"
                    name="validation"
                    options={validations}
                    placeholder="Type"
                    value={validations.find((i) => i.value === data.validation)}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={(option) => {
                      Chatbot.setBlockData(block.id, {validation: option.value});
                    }}
                  />
                </div> */}
  </>
  );
}

export const PreviewComponent = ({bot={bot}, data, node, start, decisions, elements}) => {

  return (
    <>

      <div className={'block-body node'} id={node.id}>
      {/*     <div onClick={(e) => {
            e.preventDefault();
            window.saveblock = Math.random(10);
            console.log(window.saveblock)
          }}>
            </div> */}
        
        {bot.progress !== undefined && 
          <Progress data={data} bot={bot} block={node} />
          }

            {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}

            <span className="rounded-pill  badge-bl">@{data.type}</span>

             <div className={`preview`}
               onClick={(e) => {
                e.preventDefault();
                setNode(node);
              }}
              >
                  <div className={`chat-body`}>
                     <div id="message-container">
            
                            <div className={'preview-container'}>
                                    {data.isProgress &&
                                    <div className={'progressheader'}>{data.progressheader}</div>
                                    }
                                    <div className={`preview-message`}>

                                        <h1>{data.headtext}</h1>
                                        

                                        <p dangerouslySetInnerHTML={{__html: data.content}}></p>

                                    </div>
                                  
                                  
                                    <div>
                                    <i className={`modallink`} >{data.modallink}</i>
                                    </div>

                            </div>
                        
                     </div>

                     <div className={'preview-container'}>


                          <div className={'preview-content'}>

                                    {data?.selecttype === "radio" && (    
                                          <div >       
                                            
                                            {data?.options  &&
                                                            Object.values(data?.options).map((item, index) => {
                                                              return (
                                                                <div key={index} className="choice position-relative">
                                                              
                                                                    {item.label}
                                                                    {item.hasUniqueFlow && <Handle id={item.id} className="block-handle block-handle-right" type="source" position="right" />}


                                                                </div>
                                                              );
                                                })
                                              }
                                            

                                              {/* <div className={`validation`}></div>
                                                  
                                              <span  
                                                dangerouslySetInnerHTML={{__html: data.notification}} 
                                                className={`notification`}
                                              ></span> */}
                      
                                          
                                          </div>
                                    )}
                                    
                                    { data?.selecttype === 'select' && (
                                      <>
                                      <div className='inputname'>{data.inputname}</div>
                                      <div className='input'>
                                          <span className='dropdown left'><i class="fa fa-search"></i></span>
                                          <span className='dropdown right'><i class="fa fa-angle-down"></i></span>

                                        </div>

                                      {data?.options  &&
                                        Object.values(data?.options).map((item, index) => {
                                          return (
                                            <div key={index} className="mb-3 position-relative text-right zoom-option">
                              
                         
                                            {item.hasUniqueFlow && 
                                            <>
                                            {item.label}
                                            <Handle id={item.id} className="block-handle block-handle-right" type="source" position="right" />
                                            </>
                                            }
                                            
                                          </div>
                                          );
                                      })
                                      }

                                      </>

                                    
                                    )}


                                    {data?.type === "share" && (
                                      <>
                                        <div className='inputname'>{data.emailhint}</div>
                                        <div className='flex'>
                                          <div className='input add'>{data.emailhint}</div>
                                          <button className='btn add'>+</button>    
                                        </div>
                                        <div className='input textarea'>{data.defaultmessage}</div>
                                        </>
                                        )                                                  
                                    }

                                    {data?.type === "decision" && 
                                       <> 
                                          {Object.keys(decisions).length > 2 ? (                   
                                                          Object.keys(decisions)                                                      
                                                          .map((key, i) => {
                                                            const item = decisions[key];
                                                              return (
                                                                <div key={i} className="choice position-relative">
                                                              
                                                                    {item.text}
                                                                    <Handle id={item.id} className="block-handle block-handle-right" type="source" position="right" />

                                                                </div>
                                                              );
                                                           })                                     
                                                           ) : (
                                                            
                                                           <>
                                                           </>
                                           )}     
                                                            
                                                                                                           
                                          </>                
                                        
                                                        
                                      }

                                    {data.type === "input" &&(
                                      <>
                                      <div className='inputname'>{data.inputname}</div>
                                      {data.inputtype === 'input' ?(
                                       <div className='input'>{data.hint}</div>
                                      ):(
                                      <div className='input textarea'>{data.hint}</div>)}
                                      </>
                                    )}

                                {/*     {data.type === "review" &&(
                                      
                                      Object.keys(elements)                                                      
                                      .map((key, i) => {
                                        const item = elements[key];
                                                <div key={i}>
                                                      <div className >{item.element.label}</div>
                                                      <div 
                                                     
                                                      className
                                                      ><i className={`fa fa-pencil`}></i>
                                                      </div>
                                                </div>
                                              })


                                       

                                      )} */}

                                    {data?.reviews &&
                                    Object.keys(data?.reviews)
                                        .sort()
                                        .map((key, i) => {
                                          const item = data.reviews[key];
                                          return (

                                              <div key={i}>
                                              <div className={'valuefield'} >{item.element.label}</div>
                                              <div 
                                             
                                              className
                                              ><i className={`fa fa-pencil`}></i>
                                              </div>
                                        </div>
                                      
                                            
                                          );
                                    })}



                                      {(data.notification !== '' && data.notification !== undefined)  && 
                                     <span  
                                          dangerouslySetInnerHTML={{__html: data.notification}} 
                                                className={`notification`}
                                              >

                                      </span>
                                    }


                                   {data.type === "share" &&(
                                  <div className={`validation`}>{data.validationEmail}</div>
                                  )}  
                                  <div className={`validation`}>{data.validationMessage}</div>
                                



                          </div>


                           {data.secondbutton && (
                                    <div className='mb-3 position-relative'>      
                                               <div className='btn btn-secondary'>{data.secondbutton}</div>
                                              <Handle id="next_secondary" className="block-handle block-handle-right preview" type="source_second" position="right" />
                                    </div>
                            )}

                            {data?.type === "decision" && 
                                       <> 
                                          {Object.keys(decisions).length === 1 ? (                   
                                                          Object.keys(decisions)                                                      
                                                          .map((key, i) => {
                                                            const item = decisions[key];
                                                              return (


                                                                <div key={i} className="position-relative">
                                                              
                                                                    <div className='btn'>{item.text}</div>

                                                                    <Handle id={item.id} className="block-handle block-handle-right" type="source" position="right" />

                                                                </div>
                                                              );
                                                           })                                     
                                                           ) : (
                                                            
                                                           <>
                                                           </>
                                           )}   
                                           {Object.keys(decisions).length === 2 ? (                   
                                                           <>
                                                               <div className="position-relative mb-3">
                                                                                                                              
                                                                    <div className='btn '>{Object.values(decisions)[0].text}</div>

                                                                    <Handle id={Object.values(decisions)[0].id} className="block-handle block-handle-right" type="source" position="right" />

                                                                </div>

                                                                <div className="position-relative">
                                                              
                                                                    <div className='btn btn-secondary'>{Object.values(decisions)[1].text}</div>

                                                                    <Handle id={Object.values(decisions)[1].id} className="block-handle block-handle-right" type="source" position="right" />

                                                                </div>

                                                            


                                                         </>
                                                           
                                                             
                                                                                              
                                                           ) : (
                                                            
                                                           <>
                                                           </>
                                           )}   
                                                                                           
                                         </>                
                                        
                                                        
                             }




                            {(data.type === "text" && !data.button || data?.type === "decision" && Object.keys(decisions).length < 3)  ?(
                              <></> 
                            ):(data.type === "text" && data.button === undefined ) ? (
                              <div className='btn'>{data.buttontext}</div>
                            ) : (<div className='btn'>{data.buttontext}</div>)}






                         

                     </div>

                     

                  </div>
              </div>

              {data?.type === "payment" &&              

                <div className="position-relative position-left-right mb-3">
                  <div className="right">Fail
                  <Handle id="true" className="block-handle block-handle-right" type="source" position="right" /></div>
                  <div className="left">Success
                  <Handle id="false" className="block-handle block-handle-left" type="source" position="left" /></div>
                </div>

                }

              


              <Handle id="source" className="block-handle text-center" type="target" position="top"></Handle>
              {(data.type === "text" || data.type === "input" || data.type === "multipleChoice" || data.type === "share" || data.type === "upload" || data.type === "review") &&(
            <Handle id="next" className="block-handle text-center" type="source" position="bottom"></Handle>

           )}

          {(data.type === "decision") &&(
           <Handle id="next" className="block-handle text-center" type="source" position="bottom"></Handle>
           )}

      </div>
      {(data.type === "payment") &&(
            <>
          <span className="badge badge-variable bg-primary">invoice_no</span>
          <span className="badge badge-variable bg-primary">payment_success</span>

          </>
          
           )}
  </>
  );
}

export const NodeComponent = ({bot, name, icon, data, node, start, routes, zoom}) => {

  //const zoomoutcontent = zoom <= 1;
  const zoomoutcontent = true;
  return (
    <>
        <div className="" id={node.id}>


          

          {bot.progress === undefined || bot.progress === '' || bot.progress.length < 1 ? (
            <></>
          ):(
          <Progress data={data} bot={bot} block={node} />
          )
          }


          <div
            className={`node node-sm`}
            onClick={(e) => {
              e.preventDefault();
              setNode(node);
            }}
            
          >

            {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}


            <div className="node-header">
              <i className=""><img src={'/images/icons/'+icon+'.png'} alt="..." /></i><span> {name}</span> 
            </div>

            {zoomoutcontent ? (
              <>
               
                <div className='icon'>
                 <span className='drag'>
                    <img src={'/images/icons/'+icon+'.png'} alt="..." />
                 </span>
                 </div>

                 {/*  These are essentially same, should be able to unify */}

                 {data?.type === "decision" &&              
                      Object.keys(routes)        
                                      
                        .map((key, i) => {
                          const item = routes[key];
                          return (
                            <div key={i} className="mb-3 position-relative text-right zoom-option">
                              {item.text}
                              <Handle id={item.id} className="block-handle block-handle-right" type="source" position="right" />
                          </div>
                      );
                    })                                     
                  }


                  {data?.options &&
                  Object.values(data?.options).map((item, index) => {
                          return (
                            <div key={index} className="mb-3 position-relative text-right zoom-option">
                              
                         
                              {item.hasUniqueFlow && 
                              <>
                              {item.label}
                              <Handle id={item.id} className="block-handle block-handle-right" type="source" position="right" />
                              </>
                              }
                              
                            </div>
                          );
                        })
                  }


          
                    {data.secondbutton && (
                                  <div className='mb-3 position-relative'>      
                                    <Handle id="next_secondary" className="block-handle block-handle-right" type="source_second" position="right" />
                                </div>
                          
                    )}

                </>
            ):(

              <>

                        <div className="my-3 header"><b>{data.headtext}</b></div>



                      {/*  These are essentially same, should be able to unify */}

                        {data?.type === "decision" &&   
                       
                                    Object.keys(routes)
                                            .sort()
                                            .map((key, i) => {
                                              const item = routes[key];
                                              return (
                                                <div key={i} className="mb-3 position-relative">
                                                  {item.text}
                                                  <Handle id={item.id} className="block-handle block-handle-right" type="source" position="right" />
                                                </div>
                                              );
                                      }) 
                                            
                                             
                      }



                      {data?.options &&
                      Object.values(data?.options).map((item, index) => {
                              return (
                                <div key={index} className="my-3 position-relative">
                                  <span>
                                  {item.label}
                                  </span>
                                  {item.hasUniqueFlow && <Handle id={item.id} className="block-handle block-handle-right" type="source" position="right" />}
                                  
                                </div>
                              );
                            })
                      }

                      {data?.reviews &&
                        Object.keys(data?.reviews)
                            .sort()
                            .map((key, i) => {
                              const item = data.reviews[key];
                              return (

                                  <p key={i}>{item.element.label}</p>
                          
                                
                              );
                        })}


                    

                        {data.secondbutton && (
                                      <div className='mb-3 position-relative'>      
                                        <div className='node-button'>{data.secondbutton}</div>
                                        <Handle id="next_secondary" className="block-handle block-handle-right" type="source_second" position="right" />
                                    </div>
                              
                        )}




                        <div className='node-button'>{data.buttontext}</div>
                    </>

            )}



            {data?.type === "payment"  &&              

                  <div className="position-relative position-left-right">
                    <div className="right">New client
                    <Handle id="true" className="block-handle block-handle-right" type="source" position="right" /></div>
                    <div className="left">Existing client
                    <Handle id="false" className="block-handle block-handle-left" type="source" position="left" /></div>
                  </div>

            }

     

            <span className="badge badge-variable bg-primary">{data.variable}</span>

        
          </div>

         

  
           <Handle id="source" className="block-handle text-center" type="target" position="top"></Handle>
          
          {(data.type === "text" || data.type === "input" || data.type === "multipleChoice" || data.type === "share" || data.type === "upload" || data.type === "review" || data.type === "decision") &&(
            <Handle id="next" className="block-handle text-center" type="source" position="bottom"></Handle>

           )}


        </div>

        {(data.type === "payment") &&(
            <>
          <span className="badge badge-variable bg-primary">invoice_no</span>
          <span className="badge badge-variable bg-primary">payment_success</span>

          </>
          
           )}
    


  

    </>
  );
}



export const IntNodeComponent = ({bot, name, icon, data, node, start}) => {
  

  return (
    <> 
     <div className="node int" id={node.id}>
  
  
        {(node.type === "loginBlock" || node.type === "matchBlock" || node.type === 'calendarBlock') && (
          <Progress data={data} bot={bot} block={node} />
        )}
           
          
        

        <div className={``}
         onClick={(e) => {
          e.preventDefault();
          setNode(node);
          }}>
          
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
         
          <div className="node-header"><i className=""><img src={'/images/icons/'+icon+'.png'} alt="..." /></i><span> {name}</span></div>



          <div className='icon integration'>
                 <span className='drag'>
                    <img src={'/images/icons/'+icon+'.png'} alt="..." />
                 </span>
                 </div>



          {data?.type === "condition" &&              
                <div className="mb-3 position-relative">
                <span className="fw-bolder left">F</span> {data.variable} {data.operator} {data.value}{' '}
                <span className="fw-bolder right">T</span>
                <Handle id="true" className="block-handle block-handle-right" type="source" position="right" />
                <Handle id="false" className="block-handle block-handle-left" type="source" position="left" />
                </div>
          }

            {data?.type === "scoro"  &&              

            <div className="position-relative position-left-right">

              
             <div className='left'>TIMESLOT error:      
                              <Handle id="next_secondary" className="block-handle block-handle-left" type="source_second" position="left" />
               </div>
              
           
              <div className="right">New client
              <Handle id="true" className="block-handle" type="source" position="bottom" /></div>

              <div className="right">Existing client
              <Handle id="false" className="block-handle block-handle-right" type="source" position="right" /></div>


            </div>

            

            }

            {data?.type === "match"  &&              

            <div className="position-relative position-left-right">
              
              <div className="left">No match
              <Handle id="false" className="block-handle block-handle-left" type="source" position="left" /></div>

          
            </div>

            }

            {data?.type === "calendar"  &&              

            <div className="position-relative position-left-right">
              
              <div className="left">NO TIMESLOTS
              <Handle id="false" className="block-handle block-handle-left" type="source" position="left" /></div>


            </div>

            }

          {data?.type === "calculation" &&              
              <> 
              <h4>CALCULATION</h4>
              {data.method === 'math' ?(
              <div className="my-3" dangerouslySetInnerHTML={{__html: data.formula}} />
              ):(<div className="my-3" dangerouslySetInnerHTML={{__html: 'Date Duration'}} />
              )}
              </>
          }

          {data?.type === "data" && (  
               <div className='mb-3 position-relative'>
                <b>{data?.datatype}</b>
                </div>
              )
          }
          {data?.type === "teleport" && (  
               <div className='mb-3 position-relative'>
                <span>{data?.bot}</span>
                </div>
              )
          }
          {data?.type === "data" && data.data !== undefined && ( 

                Object.entries(data.data).map(([key, { text, value }]) => (
                  <div key={key} className="position-relative">
                    {text}
                  </div>
                ))
                
          )
          }


          {data?.type === "document" && (  
                <>
               <p>{data.documentName}</p>
              <br/>
               </>
               
              )
          }

          {data?.type === "sheet" && (  
                <>
               <p>{data.documentName}</p>
               <br/>
               </>
               
              )
          }


{data?.type === "project" && (  
                <>
               <p>{data.project_name}</p>
               <br/>
               </>
               
              )
          }
          
          {data?.type === "email" && (  
                <>
                  <p>Email Variable: {data.email}</p>
               </>
               
              )
          }

          
          {(data.type !== "condition") &&(
          <span className="badge badge-variable bg-primary">{data.variable}</span>

           )}
           
          {(data.type === "calendar") &&(
            <>
          <span className="badge badge-variable bg-primary">slot</span>
          <span className="badge badge-variable bg-primary">slot_date</span>
          <span className="badge badge-variable bg-primary">slot_address</span>
          </>
           )}

         {(data.type === "scoro") &&(
            <>
          <span className="badge badge-variable bg-primary">project_id</span>
          </>
           )}
           
         {(data.type === "scoro" && data.product === 24) &&(
            <>
          <span className="badge badge-variable bg-primary">scoro_invoice</span>
          </>
           )}

{(data.type === "login") &&(
            <>
          <span className="badge badge-variable bg-primary">firstname</span>
          <span className="badge badge-variable bg-primary">lastname</span>
          <span className="badge badge-variable bg-primary">phone</span>
          <span className="badge badge-variable bg-primary">email</span>
          <span className="badge badge-variable bg-primary">pid</span>
          </>
           )}

         {(data.type === "match") &&(
          <>
          <span className="badge badge-variable bg-primary">lawyer</span>
          <span className="badge badge-variable bg-primary">selected_service_price</span>
          <span className="badge badge-variable bg-primary">partner_email</span>
          <span className="badge badge-variable bg-primary">partner_name</span>
          <span className="badge badge-variable bg-primary">partner_company_name</span>
          <span className="badge badge-variable bg-primary">partner_company_reg_id</span>
          <span className="badge badge-variable bg-primary">partner_company_vat_id</span>
          <span className="badge badge-variable bg-primary">partner_company_address</span>
          <span className="badge badge-variable bg-primary">partner_hourlyprice</span>
          <span className="badge badge-variable bg-primary">partner_videocall_url</span>
          <span className="badge badge-variable bg-primary">partner_crm_id</span>




          </>
          )}




         
                {data.secondbutton && (
                                  <div className='mb-3 position-relative'>      
                                    <Handle id="next_secondary" className="block-handle block-handle-right" type="source_second" position="right" />
                                </div>
                          
                    )}


        </div>


        <Handle id="target" className="block-handle" type="target" position="top" />
          
          {(data?.type !== "condition" && data?.type !== "teleport" && data?.type !== "scoro") &&
           <Handle id="next" className="block-handle" type="source" position="bottom" />
           }
         
          
      
        </div>

       
    </>
  );
}




export const ProgressOld = ({bot, block, data, onChange}) => {

  

  const optionSelectRef = useRef(null);
  const [progs, setProgs] = useState([{id: '00', label: 'off', value: 'off', step: 'off'}, ...bot.progress])
  const maxprogress = bot.progress.length;

    const formatOptionLabel = ({ label }, { context }) => {
      if (context === 'menu') {
        const index = bot.progress.findIndex((option) => option.label === label);
        if(index === -1){
          return (
            
            <div>
                {label}
            </div>
            )  
          
        }else{
        return (
          <>
          <div>
             {index + 1}
          </div>
          <span>{label}</span>
          </>
        );
        }
      } else if (context === 'value') {
   
            const index = bot.progress.findIndex((option) => option.label === label);
            return (       
                  <div>
                  {index + 1}
                  </div>
            );    
      }


      return label;
    };
    const [selectedOption, setSelectedOption] = useState(data.progressheader);
    const [closed, setClosed] = useState(false);

    /* Using this to close react component globally. Not the best approach. */
    useEffect(() => {
      if(window.progress !== block.id){
        setClosed(false)
      }
    }, [window.progress])
  

    const handleSelectChange = async(selectedOption) => {
      setSelectedOption(selectedOption);
    };

    useEffect(() => {
      if(optionSelectRef.current === null) return
      if(optionSelectRef.current.state.value){
        Chatbot.setBlockData(block.id, {isProgress: true, progress: optionSelectRef.current.state.value.step, progressheader: optionSelectRef.current.state.value.value, progressheader_en: optionSelectRef.current.state.value.value}, true);
        if (optionSelectRef.current.state.value.step === 'off') {
          Chatbot.setBlockData(block.id, {isProgress: false, progress: '+'}, true);
          setSelectedOption('+');
        }
      }
      setClosed(closed ? false : true, closed ? true : false)
    }, [selectedOption]);
    
    const handleOpen = () => {
      setClosed(closed ? false : true, closed ? true : false)
      window.progress = block.id;
    }


  return (
    <>
   {/*   <div  onClick={()=> {setClosed(closed ? false : true, closed ? true : false)}}>
            closed 
            </div> */}
           <div className={'progress-indicator'}>
                    <table width={100 * data.progress / maxprogress + '%'} className={'progress'}></table>
          </div>


        {!closed ? (
          <div  className='progress-select-icon' onClick={()=> {handleOpen()}}>{data.progress === undefined ?('+'):(data.progress)}</div>
        ):(


       <>

        

          <div className="mb-2 nodrag absolute">
                     
                      <Select             
                      ref={optionSelectRef}
                      autoFocus={false}
                      defaultMenuIsOpen={false}
                      options={progs}
                      menuIsOpen={true}
                      id="choice"
                      name="choice"
                      className={selectedOption?.value === "off" || data.isProgress !== true ? "progress-off progress-container" : "progress-container"}
                      placeholder={data.isProgress === true ? data.progress : '+'}
                      defaultValue={data.progressheader}
                      value={selectedOption}
                      classNamePrefix="progress-select"
                      menuPlacement="bottom"
                      formatOptionLabel={formatOptionLabel}                    
                      onChange={handleSelectChange}
                      onBlur={async (e) => {
                
                      /*   if(optionSelectRef.current.state.value){
                            Chatbot.setBlockData(block.id, {isProgress: true, progress: optionSelectRef.current.state.value.step, progressheader: optionSelectRef.current.state.value.value}, true);
                            if (optionSelectRef.current.state.value.step === 'off') {
                              Chatbot.setBlockData(block.id, {isProgress: false, progress: '+'}, true);
                              setSelectedOption('+');
                            }
                          } ; */
                         /*  setClosed(closed ? false : true, closed ? true : false) */
                        }}
                      />
             
               </div>
               </>
               )}
    </>
  );
}


export const Progress = ({ bot, block, data, onChange }) => {
  const optionSelectRef = useRef(null);

  // Set up progress options, including a default 'off' option
  const [progs, setProgs] = useState([{ id: '00', label: 'off', value: 'off', step: 'off' }, ...bot.progress]);
  const maxprogress = bot.progress.length;
  const [selectedOption, setSelectedOption] = useState(data.progressheader);
  const [closed, setClosed] = useState(false);

  // Format options with step index display
  const formatOptionLabel = ({ label }, { context }) => {
    if (context === 'menu') {
      const index = bot.progress.findIndex((option) => option.label === label);
      if(index === -1){
        return (
          
          <div>
              {label}
          </div>
          )  
        
      }else{
      return (
        <>
        <div>
           {index + 1}
        </div>
        <span>{label}</span>
        </>
      );
      }
    } else if (context === 'value') {
 
          const index = bot.progress.findIndex((option) => option.label === label);
          return (       
                <div>
                {index + 1}
                </div>
          );    
    }


    return label;
  };

  // Helper to get progress headers based on bot.progress and selected step
  const getProgressHeaders = (step) => {
    const selectedProgress = bot.progress.find((option) => option.step === step);
    if (selectedProgress) {
      return {
        progressheader: selectedProgress.label || '',
        progressheader_en: selectedProgress.label_en || '',
        progressheader_ru: selectedProgress.label_ru || '',
      };
    }
    return { progressheader: '', progressheader_en: '', progressheader_ru: '' };
  };

  // Synchronize progress data with locale-specific headers
  useEffect(() => {
    if (optionSelectRef.current === null) return;

    const { step, value } = optionSelectRef.current.state.value || {};
    if (step !== undefined) {
      const headers = getProgressHeaders(step);

      // Set progress data with locale-specific headers
      Chatbot.setBlockData(block.id, {
        isProgress: step !== 'off',
        progress: step !== 'off' ? step : '+',
        ...headers
      }, true);

      setSelectedOption(step === 'off' ? '+' : value);
    }

    setClosed(false);
  }, [selectedOption]);

  // Effect to close component globally if window.progress changes
  useEffect(() => {
    if (window.progress !== block.id) {
      setClosed(false);
    }
  }, [window.progress]);

  const handleSelectChange = async (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleOpen = () => {
    setClosed((prevClosed) => !prevClosed);
    window.progress = block.id;
  };

  return (
    <>
      <div className='progress-indicator'>
        <table width={`${(100 * data.progress) / maxprogress}%`} className='progress'></table>
      </div>

      {!closed ? (
        <div className='progress-select-icon' onClick={handleOpen}>
          {data.progress === undefined ? '+' : data.progress}
        </div>
      ) : (
        <div className="mb-2 nodrag absolute">
          <Select
            ref={optionSelectRef}
            autoFocus={false}
            defaultMenuIsOpen={false}
            options={progs}
            menuIsOpen={true}
            id="choice"
            name="choice"
            className={`progress-container ${selectedOption?.value === 'off' || data.isProgress !== true ? "progress-off" : ""}`}
            placeholder={data.isProgress ? data.progress : '+'}
            defaultValue={data.progressheader}
            value={selectedOption}
            classNamePrefix="progress-select"
            menuPlacement="bottom"
            formatOptionLabel={formatOptionLabel}
            onChange={handleSelectChange}
          />
        </div>
      )}
    </>
  );
};
