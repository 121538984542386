import React, {useEffect, useState} from 'react';
import {Field} from 'formik';
import {filter as _filter} from 'lodash';
import { useLocation } from 'react-router-dom';
import {useHistory, useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {Form, DataTable} from '../components';
import Select from 'react-select';
import {languages, countries} from '../config';
import {axios} from '../libs';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import {SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import {InputComponent} from '../components/ChatbotBuilder/Components';
import {roles} from '../config';
import {User} from '../states/actions';
import UpdateLawyersDataButton from '../components/UpdateLawyersDataButton';
import UpdateWordpressDataButton from '../components/UpdateWordpressDataButton';

const DragHandle = sortableHandle(() => <span> : : </span>);

const SortableItem = SortableElement(({ value, handleInputChange, remove }) => (
  <div>
    <DragHandle />
    <input
      type="text"
      defaultValue={value}
      onBlur={handleInputChange}
    />
    <button onClick={remove}>Remove</button>
  </div>
));

const SortableList = SortableContainer(({ items, handleInputChange, remove }) => (
  <ul>
    {items.map((item, index) => (
      <SortableItem
        key={item.id}
        index={index}
        value={item.value}
        handleInputChange={(e) => handleInputChange(e, index)}
        remove={(e) => remove(item.id)}
      />
    ))}
  </ul>
));

export const Users = () => {
  const [newUser, setNewUser] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const addUser = () => {
    setNewUser(true);
    openModal();
    push('/users');
  };

  // Updated initialValues include partner details and new fields;
  // partner_vat is now a boolean (default false)
  const [initialValues, setinitialValues] = useState({
    email: '',
    role: '',
    firstName: '',
    lastName: '',
    partner_company_name: '',
    partner_IBAN: '',
    partner_company_address: '',
    partner_hourly_rate: '',
    partner_vat: false,
    partner_company_reg_id: '',
    partner_company_vat_id: '',
  });
  const {push} = useHistory();
  const {userId} = useParams();
  const [refetch, setrefetch] = useState(false);

  useEffect(() => {
    if (userId) {
      (async () => {
        const {user} = await axios.get(`/user/${userId}`);
        setinitialValues({
          _id: userId,
          email: user.email,
          role: user.role,
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          user_no: user.user_no || '',
          partner_company_name: user.partner_company_name || '',
          partner_IBAN: user.partner_IBAN || '',
          partner_company_address: user.partner_company_address || '',
          partner_hourly_rate: user.partner_hourly_rate || '',
          partner_vat: user.partner_vat || false,
          partner_company_reg_id: user.partner_company_reg_id || '',
          partner_company_vat_id: user.partner_company_vat_id || '',
        });
      })();
    }
  }, [userId]);

  // Updated columns: using fullName, and adding user_no
  const tableColumns = [
    {
      dataField: 'fullName',
      text: 'Name',
      formatter: (v, {firstName, lastName}) => {
        return `${firstName ?? ''} ${lastName ?? ''}`;
      },
    },
    {
      dataField: 'user_no',
      text: 'User ID',
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'role',
      text: 'Role',
    },
  ];

  const userRequestColumns = [
    {
      dataField: 'firstName',
      text: 'Name',
      formatter: (v, {firstName, lastName}) => {
        return `${firstName ?? ''} ${lastName ?? ''}`;
      },
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'request',
      text: 'Request Message',
    },
    {
      dataField: 'createdAt',
      text: 'Requested At',
      formatter: (date) => {
        return date.split('T')[0];
      },
    },
  ];

  const onSuccess = () => {
    setrefetch(false);
    if (userId) {
      setrefetch(true);
      push('/users');
    } else {
      setrefetch(true);
    }
  };

  const onEdit = ({_id}) => {
    setNewUser(false);
    push(`/users/${_id}`);
    openModal();
  };

  const onDelete = ({_id}) => {
    setrefetch(false);
    window.nConfirm({
      title: 'Warning',
      text: 'Are you sure you want to delete this user?',
      icon: 'fa-exclamation-circle',
      onYes: () => {
        deleteUser(_id);
      },
    });
  };

  const deleteUser = async (id) => {
    await axios.delete(`/user/${id}`);
    setrefetch(true);
  };

  const actions = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="row row-cols-auto">
        <h6>
          <span
            onClick={() => {
              console.log('Accept');
            }}
            className="mx-1 badge bg-primary"
          >
            Accept
          </span>
        </h6>
        <h6>
          <span
            onClick={() => {
              console.log('Reject');
            }}
            className="mx-1 badge bg-danger"
          >
            Reject
          </span>
        </h6>
      </div>
    );
  };

  const userRequestActions = {
    dataField: '',
    text: 'Actions',
    formatter: actions,
    style: {
      width: 200,
    },
  };

  return (
    <>
      <div className="row table_v2">
        <div className="col p-0">
          <div className="x_panel chatbot-builder">
            <div className="header-menu d-flex justify-content-between">
              <div className="d-flex justify-content-end page-top-container">
                <div className='btn-add link' onClick={addUser}>
                  + Add User
                </div>
                <UpdateLawyersDataButton />
                {/* <UpdateWordpressDataButton /> */}
              </div>
            </div>
            <div className="x_content">
              <div className="page-container">
                <DataTable
                  refetch={refetch}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  url="/user/all"
                  dataKey="users"
                  tableHeading="Users"
                  columns={tableColumns}
                  search={false} 
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="modalclose" onClick={closeModal}>
              &times;
            </span>

            <div className='modal_header mb-4 my-4'>
              {newUser ? (
                <h2>Add new user</h2>
              ) : (
                <h2>Edit user</h2>
              )}
            </div>

            <Form url="/user" usePUT={userId ? true : false} initialValues={initialValues} onSuccess={onSuccess}>
              <div className="row mb-5">
                <label htmlFor="firstName" className="form-label comp-header">
                  First Name
                </label>
                <div>
                  <Field
                    as="input"
                    id="firstName"
                    name="firstName"
                    className="form-control"
                    placeholder="First name"
                    autoComplete="off"
                  />
                </div>
              </div>
              
              <div className="row mb-5">
                <label htmlFor="lastName" className="form-label comp-header">
                  Last Name
                </label>
                <div>
                  <Field
                    as="input"
                    id="lastName"
                    name="lastName"
                    className="form-control"
                    placeholder="Last name"
                    autoComplete="off"
                  />
                </div>
              </div>

              {userId && (
                <div className="row mb-5">
                  <label htmlFor="user_no" className="form-label comp-header">
                    User ID
                  </label>
                  <div>
                    <Field
                      as="input"
                      id="user_no"
                      name="user_no"
                      className="form-control"
                      placeholder="User ID"
                      autoComplete="off"
                    />
                  </div>
                </div>
              )}

              <div className="row mb-5">
                <label htmlFor="email" className="form-label comp-header">
                  User email
                </label>
                <div>
                  <Field
                    as="input"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="User email"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="row mb-5">
                <label htmlFor="role" className="form-label comp-header">
                  Role
                </label>
                <div>
                  <Field id="role" name="role">
                    {({field, form}) => (
                      <Select
                        id="role"
                        name={field.name}
                        value={roles.find((i) => i.value === form.values.role)}
                        options={roles}
                        onChange={(option) => {
                          form.setFieldValue(field.name, option.value);
                        }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: 0,
                          }),
                        }}
                      />
                    )}
                  </Field>
                </div>
              </div>

              {/* Partner Details Fields */}
              <div className="row mb-5">
                <h4>Partner Details</h4>
              </div>

              <div className="row mb-5">
                <label htmlFor="partner_company_name" className="form-label comp-header">
                  Partner Company Name
                </label>
                <div>
                  <Field
                    as="input"
                    id="partner_company_name"
                    name="partner_company_name"
                    className="form-control"
                    placeholder="Partner Company Name"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="row mb-5">
                <label htmlFor="partner_IBAN" className="form-label comp-header">
                  Partner IBAN
                </label>
                <div>
                  <Field
                    as="input"
                    id="partner_IBAN"
                    name="partner_IBAN"
                    className="form-control"
                    placeholder="Partner IBAN"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="row mb-5">
                <label htmlFor="partner_company_address" className="form-label comp-header">
                  Partner Company Address
                </label>
                <div>
                  <Field
                    as="input"
                    id="partner_company_address"
                    name="partner_company_address"
                    className="form-control"
                    placeholder="Partner Company Address"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="row mb-5">
                <label htmlFor="partner_hourly_rate" className="form-label comp-header">
                  Partner Hourly Rate
                </label>
                <div>
                  <Field
                    as="input"
                    id="partner_hourly_rate"
                    name="partner_hourly_rate"
                    className="form-control"
                    placeholder="Partner Hourly Rate"
                    autoComplete="off"
                  />
                </div>
              </div>

              {/* New Partner Additional Fields */}
              <div className="row mb-5">
                <label htmlFor="partner_company_reg_id" className="form-label comp-header">
                  Partner Company Reg ID
                </label>
                <div>
                  <Field
                    as="input"
                    id="partner_company_reg_id"
                    name="partner_company_reg_id"
                    className="form-control"
                    placeholder="Partner Company Reg ID"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="row mb-5">
                <label htmlFor="partner_company_vat_id" className="form-label comp-header">
                  Partner Company VAT ID
                </label>
                <div>
                  <Field
                    as="input"
                    id="partner_company_vat_id"
                    name="partner_company_vat_id"
                    className="form-control"
                    placeholder="Partner Company VAT ID"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="row mb-5">
                <label htmlFor="partner_vat" className="form-label comp-header">
                  Partner VAT
                </label>
                <div className="form-check">
                  <Field
                    type="checkbox"
                    id="partner_vat"
                    name="partner_vat"
                    className="form-check-input"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <button className="light-btn" onClick={closeModal}>
                  Cancel
                </button>
                <button type="submit" className="main-btn">
                  Save
                </button>
              </div>
            </Form>
          </div>
        </div>
      )}

      {User.isAdmin() && (
        <div className="row">
          <div className="col">
            <div className="x_panel">
              <div className="x_title">
                <h2>User Requests</h2>
                <div className="clearfix"></div>
              </div>
              <div className="x_content">
                <DataTable
                  url="/user-request"
                  dataKey="userRequest"
                  columns={userRequestColumns}
                  actions={userRequestActions}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
