import React, {useEffect, useRef, useState, useCallback, useContext} from 'react';

import ReactFlow, {MarkerType, applyNodeChanges, applyEdgeChanges, Controls, Background, NodeToolbar, isEdge} from 'reactflow';
import 'reactflow/dist/style.css';

// default styling

import {Sidebar} from '../components/ChatbotBuilder';
import {axios} from '../libs';
import {Chatbot} from '../states/actions';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {nodeTypes} from '../components/ChatbotBuilder/nodeTypes';
import {Form} from '../components';
import {Field} from 'formik';
import {find as _find, filter as _filter} from 'lodash';

import {BlockEditor} from '../components/ChatbotBuilder';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';

import Chat from '../components/ChatbotBuilder/ChatWidget/Chat';


import {User} from '../states/actions';

//USER-APP specific
import 'react-datepicker/dist/react-datepicker.css';
//import validationError from '../../../server/helpers/validation-error';

import { GlobalStateContext } from '../components/GlobalState';

  
//const ch = await Chatbot.get();
//const {name, blocks_init} = ch;

export const onDelete = async (id) =>  {
  Chatbot.removeBlock(id, true);
}


const CustomConfirmationDialog = ({ isOpen, message, onConfirm, onCancel, type }) => {
  if (!isOpen) return null;

  return (
    <>
    <div 
      className={`confirmation-dialog ${type}`}
      onClick={onCancel}
      >
        <p>{message}</p>
        
        {type === "confirm" && (
        <>
        <button onClick={onConfirm}>Yes</button>
        <button className="strong" onClick={onCancel}>No</button>
        </>
         )}
      
      </div>
     <div className={`confirmation-dialog-bg`} onClick={onCancel}></div>
     </>
  );
};

// Custom hook to handle the confirmation dialog
const useCustomConfirm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [resolve, setResolve] = useState(null);

  const confirm = (msg) => {
    setMessage(msg);
    setIsOpen(true);

    return new Promise((_resolve) => {
      setResolve(() => _resolve);
    });
  };

  const handleConfirm = () => {
    setIsOpen(false);
    resolve(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
    resolve(false);
  };

  return { CustomConfirmation: () => <CustomConfirmationDialog isOpen={isOpen} message={message} onConfirm={handleConfirm} onCancel={handleCancel} type='confirm' />, confirm };
};



export const ChatbotBuilder = React.memo(() => {

  const { CustomConfirmation, confirm } = useCustomConfirm();

  const { globalState, setGlobalState } = useContext(GlobalStateContext);

  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const graphStyles = { width: "100%", height: "90vh" };


  const [loaded, setLoaded] = useState(false);

  const [sidebarstate, setSidebarstate] = useState(false);
  const [editorstate, setEditorstate] = useState(false);

  const [editelement, setEditElement] = useState();
  const [isedited, setEdited] = useState(false);
  const [isnode, setNode] = useState();
  const [isnew, setNew] = useState(false);

  const [nodedata, setNodeData] = useState();

  const [selectedNodes, setSelectedNodes] = useState([]);

  const chatbot = useSelector(Chatbot.get());

  let state = "";
  switch (globalState.preview) {
    case undefined:
      state = true;
      setGlobalState({...globalState, preview: true})
      break;
    case true:
      state = true;
      break;
    case false:
      state = false;
      break;
    default:
      state = false;
      break;
  }
  const [preview, setPreviewMode] = useState(state);

  const {chatbotId} = useParams();
 
  const {name, blocks} = chatbot;


  const [nodes, setNodes] = useState();
  const [edges, setEdges] = useState();



/* const onNodesChange  = useCallback(
  (changes) => setNodes((ns) => applyNodeChanges(changes, ns)),
  []
); 
const onEdgesChange = useCallback(
  (changes) => setEdges((es) => applyEdgeChanges(changes, es)),
  []
);
*/

  const [tempNodeChanges, setTempNodeChanges] = useState([]);
  const [tempEdgeChanges, setTempEdgeChanges] = useState([]);
  const [deleteEdge, setDelete] = useState(false);


  const onNodesChange = useCallback(async(changes) => {
    
    const deleteNodes = changes.filter(change => change.type === 'remove');
    if (deleteNodes.length > 0) {
      setTempNodeChanges(changes)
      document.getElementById(deleteNodes[0].id).classList.add("remove");
      //const confirmDelete = window.confirm('Are you sure you want to delete this node?');
      const confirmDelete = await confirm('Are you sure you want to delete this node?');
      if (!confirmDelete) {
        document.getElementById(deleteNodes[0].id).classList.remove("remove");
        setTempNodeChanges([]);
        setTempEdgeChanges([]);
        return;
      } else {
        // Call nodeDelete function if the user confirms node deletion
        nodeDelete(deleteNodes);
        // NOT THE BEST APPROACH, BUT IT WORKS - using it because of the async nature of the confirm function
        setDelete(edgesToDelete)
        //setNodes((ns) => applyNodeChanges([...tempNodeChanges, ...changes], ns));
      }
    }
    setNodes((ns) => applyNodeChanges(changes, ns));
  },
  []
  //[tempNodeChanges, tempEdgeChanges, nodeDelete]
);

  const onEdgesChange = useCallback(
    (changes) => {

      console.log(changes)
      const isEdgeRemoved = changes.some(change => change.type === 'remove');
      if (isEdgeRemoved) {
        // Store edge changes temporarily
        setTempEdgeChanges(changes);
      } else {
        // Apply edge changes immediately if they are not related to edge removal
        setEdges((es) => applyEdgeChanges(changes, es));
      }
    },
    []
  ); 




  //This is used to delete edges when a node is deleted, worked only when did not use asyncronous function. 
  useEffect(() => {
    console.log("called")
    if(tempEdgeChanges.length > 0 && tempNodeChanges.length === 0){
    console.log(tempEdgeChanges)
    setEdges((es) => applyEdgeChanges(tempEdgeChanges, es))
    deleteEdges()  
    }
  }, [tempEdgeChanges]);


  //Better name for this function, maybe confirmDelete
  useEffect(() => {
    console.log("called")
    if(edgesToDelete.length > 0){
      console.log(edgesToDelete)  
      deleteEdges()
    }
  }, [deleteEdge]);

  
  const deleteEdges = async() => {
    console.log(edgesToDelete.length)
     for (let i = 0; i < edgesToDelete.length; i++) {
       console.log("ID " + edgesToDelete[i].id)
       Chatbot.removeBlock(edgesToDelete[i].id, true);
       if(edgesToDelete[i].sourceHandle === "next_secondary"){
        console.log("deleting secondary" + edgesToDelete[0].source)
        //SOMETHING WRONG HERE, NEED TO FIX
        Chatbot.setBlockData(edgesToDelete[i].source, {next_secondary : ""})
       }
     }
     setEdgesToDelete([]);
  }



  const [edgesToDelete, setEdgesToDelete] = useState([]);
  /* This is called when edge is deleted.
    Since actual delete happens in deleteEdges function, this function is used to store the edges to be deleted.
    Need to move other edge types to delete to other function as well.
  */
  const storeEdges = (params) => {
     console.log("KUSTUTAN NEED: ", params.length, params)

     setEdgesToDelete(params); 
  };



/*  useEffect(() => {
  if(edgesToDelete.length > 0){
    console.log(edgesToDelete)  
  }
}, [edgesToDelete]); */
 


 const nodeDelete = (blocksToDelete) =>  {
  console.log(blocksToDelete)
     console.log("deleting nodes" + blocksToDelete[0].id);
     setEditElement();
     setEditorstate(false)
     Chatbot.removeBlock(blocksToDelete[0].id, true);
 }

 
 


  
  

  const oldWarn = console.warn;
  console.warn = (warning) => {
    if (warning.includes(`Couldn't create edge for`)) {
     
      const handleId = warning.split('edge id:').pop();
      console.log(`Removing un-created handles`, handleId.trim());
      const removeId = handleId.slice(0, handleId.indexOf('Help:')).replace(/\./g, '').replace(/\s/g, '');
      console.log(removeId)
      Chatbot.removeBlock(removeId, true);
    } else {
      oldWarn(warning);
    }
  };
  

 /*  console.warn = (warning) => {
    if (warning.includes(`couldn't create edge for`)) {
      const handleId = warning.split(':').pop();
      console.log(`Removing un-created handles`, handleId.trim());
      Chatbot.removeBlock(handleId.trim(), true);
    } else {
      oldWarn(warning);
    }
  }; */



  useEffect(() => {
    setNode(isnode)
  }, [isnode]);

  useEffect(() => {
   // console.log(selectedNodes)
    window.blockstocopy = selectedNodes
  }, [selectedNodes]);

  useEffect(() => {
    console.log(window.appid)
    window.appid = chatbotId;
    if (window.location.href.includes('apptest')) {
       window.appurl = 'https://test.hugo.legal/hugoapp?bot='  
    }else if(window.location.href.includes('localhost')){
      window.appurl = 'http://localhost:3001/?bot='  
    }
     else {
      window.appurl = 'https://app.hugo.legal/hugoapp?bot='  
    }
    setGlobalState({...globalState, appid: chatbotId})
  }, [window.appid]);


  useEffect(() => {
    window.collapseSidebar();
    Chatbot.fetch(chatbotId).then((chatbot) => {
      console.log("load and set CHATBOT")
      Chatbot.set(chatbot);
      window.appid = "test";
    })
    //get chatbot blocks
  }, [chatbotId]);
  
  useEffect(() => {
    console.log("Handle input change")
    if(!window.validationError) return null;
    validateInputs(window.validate)
  }, [window.handleInput]);

  useEffect(() => {
    console.log(window.isEdited)
    if(window.isEdited === undefined) return null;
    setEdited(window.isEdited)
  }, [window.isEdited]);

  //Load initial nodes and edges from block data, and do it only once.  
  


  useEffect(() => {


    if(loaded) return
    if(Object.values(blocks).length > 0){
      console.log(Object.values(blocks).length)
      console.log("LAADIS")

    /*  Use this to clean up old blocks */
   /*   const del = Object.values(blocks).filter(data => data.type === 'step' || data.type === 'smoothstep')
      console.log(del.length)
      for (let i = 0; i < del.length; i++) {
        console.log(del[i].id)
        Chatbot.removeBlock(del[i].id, true);
      } */

      setNodes(Object.values(blocks).filter(data => data.type !== 'step' && data.type !== 'smoothstep'))
      setEdges(Object.values(blocks).filter(data => data.type === 'step' || data.type === 'smoothstep'))
      setLoaded(true);
      console.log(Object.values(blocks).length)

      //setPreviewMode(chatbot.preview)
    }
  }, [blocks]);



  const handleSelectionChange = (elements) => {
    if (elements.nodes[0] !== undefined) {
        setNode(elements.nodes[0].id)
    }
  };





  
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [notificationDialog, setNotificationDialog] = useState();

  const NotificationDialog = ({show, onConfirm, onCancel, type, message}) => {
    // if (!show) return null;
     return (
       <div 
       className={`confirmation-dialog ${type}`}
       onClick={onCancel}
       >
         <p>{message}</p>
         
         {type === "confirm" && (
         <>
         <button onClick={onConfirm}>Yes</button>
         <button className="strong" onClick={onCancel}>No</button>
         </>
          )}
       
       </div>
     );
   };


  //PREVIOUS DELETE LOGIC

  /*
  const [nodeToDelete, setNodeToDelete] = useState(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Delete") {
        console.log("NODE delete key")

        if (isnode) {

        //console.log(isnode, window.el)
         document.getElementById(isnode).classList.add("remove");
         // window.nConfirm({onYes:{handleConfirmDelete}, onNo:{handleCancelDelete}, title: 'Are you sure you want to delete this block?', icon: 'fa-exclamation-triangle'});
         setShowConfirmation(true);
         setNotificationDialog(<NotificationDialog  onConfirm={handleConfirmDelete} onCancel={handleCancelDelete} message="Are you sure you want to delete this block?" type="confirm"/>)
         setNodeToDelete(isnode);

        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [nodes]);

  const handleConfirmDelete = () => {
    const newNodes = nodes.filter((node) => node.id !== nodeToDelete);
    setNodes(newNodes);
    setShowConfirmation(false);
    setNodeToDelete(null);
    nodeDelete0(isnode)
  };

  const handleCancelDelete = (nodeid) => {
    setShowConfirmation(false);
    setNodeToDelete(null);
    document.getElementById(isnode).classList.remove("remove");
  };

  const nodeDelete0 = async (id) =>  {
    setEditElement();
    setEditorstate(false)
    Chatbot.removeBlock(id, true);
 } */





 




// not in use?
  const setPreview = (state) => {

    setPreviewMode(state);

    if (!globalState.preview) {
      setGlobalState({...globalState, preview: true})
    } else {
      setGlobalState({...globalState, preview: false})  
    }
   /*  const data = {
      preview: state
     };
    Chatbot.setChatbotData0(chatbotId, data); */
  }

  const onNodeDragStop = (event, node) => {
    // //check if edit mode on
    const editMode = blocks[node.id].editMode;
    Chatbot.setBlockData(
      node.id,
      {
        meta: {
          position: node.position,
        },
      },
      !editMode,
    );
  };


  const onConnect = async (params) => {
    console.log(`file: ChatbotBuilder.js | line 97 | params`, params);
    const blockType = blocks[params.source].data.type;
    const _id = await getBlockId();  

    let label = null;
    if (blockType === 'decision' && params.sourceHandle !== "next") {
      label = `${blocks[params.source].data['variable']} : ${
        blocks[params.source].data.decisions[params.sourceHandle].text
      }`;
    } else {
      label = blocks[params.source].data['variable'] || '';
    }

    const handle = {
      id: _id,
      source: params.source,
      sourceHandle: params.sourceHandle,
      target: params.target,
      targetHandle: params.targetHandle,
      type: 'smoothstep',
      pathOptions: {borderRadius: 30},
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
        color: '#BBC2E7',
      },
      // label,
      data: {},
      style: { width: 100, fontSize: 11, color: 'white', strokeWidth: 3, stroke: '#BBC2E7', },
    };
    setEdges(edges => [...edges, handle]);

    Chatbot.setBlock(handle);
    Chatbot.setBlockData(
      _id,
      {
        _id,
        type: 'handle',
        chatbotId,
        meta: {handle},
      },
      true,
    );
    /* Chatbot.setBlockData(
      params.target,
      {
        previous: params.source,
      },
      true,
    ); */


    if (blockType === 'match' | blockType === 'calendar') {

      if(params.sourceHandle === 'false'){
        Chatbot.setBlockData(
          params.source,
          {
            [params.sourceHandle === 'true' ? 'nextOnTrue' : 'nextOnFalse']: params.target,
          },
          true,
        );
      }else{

        Chatbot.setBlockData(
          params.source,
          {
            [params.sourceHandle]: params.target,
          },
          true,
        );
      } 
    } else if (blockType === 'scoro') {
      if(params.sourceHandle === "next_secondary"){
        Chatbot.setBlockData(
          params.source,
          {
            ['next_secondary']: params.target,
          },
          true,
        );
      }else{
      Chatbot.setBlockData(
        params.source,
        {
          [params.sourceHandle === 'true' ? 'nextOnTrue' : 'nextOnFalse']: params.target,
        },
        true,
      );
      }
    }else if (blockType === 'condition' || blockType === 'payment') {
      Chatbot.setBlockData(
        params.source,
        {
          [params.sourceHandle === 'true' ? 'nextOnTrue' : 'nextOnFalse']: params.target,
        },
        true,
      );
    } else if (blockType === 'decision') {


  /*     Chatbot.setBlockData(
        params.source,
        {
          decisions: {
            [params.sourceHandle]: {next: params.target},
          },
        },
        true,
      );  */
      console.log("OARA:" , params.sourceHandle)

      if (params.sourceHandle === 'next') {
        console.log("SIIIIIN");
        Chatbot.setBlockData(
          params.source,
          {
            next: params.target,
          },
          true,
        );
      } else {


      const decisions = Object.entries(blocks[params.source].data.decisions || {})
          .sort(([a], [b]) => a.localeCompare(b))
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
          
        Chatbot.setBlockData(
          params.source,
          { 
             decisions: {
               ...decisions, [params.sourceHandle]: { next: params.target }
             } 
          } ,
          true
        );
      }

    } else if (blockType === 'multipleChoice') {
      if (params.sourceHandle === 'next') {
        Chatbot.setBlockData(
          params.source,
          {
            next: params.target,
          },
          true,
        );
      } else {
        const _options = blocks[params.source].data.options;
        const _option = _find(_options, {id: params.sourceHandle});
        Chatbot.setBlockData(
          params.source,
          {
            options: {..._options, [_option.id]: {..._option, next: params.target}},
          },
          true,
        );
      }
    } else if (blockType === 'input' || blockType === 'share' || blockType === 'upload') {
      Chatbot.setBlockData(
        params.source,
        {
          [params.sourceHandle]: params.target,
        },
        true,
      );
    } else {
      Chatbot.setBlockData(
        params.source,
        {
          next: params.target,
        },
        true,
      );
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  function validateInputs(el) {
    // Remove the `invalid` class from all elements in the document
    document.querySelectorAll('.invalid').forEach((element) => {
      element.classList.remove('invalid');
    });
  
    // Ensure `el` is an array and not empty
    if (!Array.isArray(el) || el.length === 0) {
      console.warn('No invalid elements provided.');
      return;
    }
  
    console.log('Invalid IDs to validate:', el);
  
    let firstInvalidElement = null;
  
    // Validate each element in the provided array
    el.forEach((key) => {
      const inputElement = document.getElementById(key);
  
      if (inputElement) {
        // Check if input element has a value property and is a string
        if (typeof inputElement.value === 'string' && inputElement.value.trim() !== "") {
          // Input contains information (is valid)
          inputElement.classList.add("valid");
          inputElement.classList.remove("invalid");
        } else {
          // Input is empty or contains only whitespace (is not valid)
          inputElement.classList.add("invalid");
          inputElement.classList.remove("valid");
  
          // Keep track of the first invalid input to scroll to it
          if (!firstInvalidElement) {
            firstInvalidElement = inputElement;
          }
        }
      } else {
        console.warn(`Element with ID "${key}" not found in the DOM.`);
      }
    });
  
    // Scroll to the first invalid element if it exists
    if (firstInvalidElement) {
      console.log(`Scrolling to first invalid element: ${firstInvalidElement.id}`);
      firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      console.log('No invalid input elements found to scroll to.');
    }
  }
  
  
  

/*   const onEdgeClick = async (event, node) =>  {
    console.log(node)
  } */


  const onDrop = async (e) => {
    /* TODO
    Check if there are other new blocks being added, if not saved yet (validation issues) then dont continue. window method is not good because...
     */
    console.log('onDrop')
    if(editorstate) saveBlock();
    setNew(true) 
    setEditorstate(false)

    try {
      e.preventDefault();
      const {type, blockType} = JSON.parse(e.dataTransfer.getData('blockType'));
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: e.clientX - reactFlowBounds.left,
        y: e.clientY - reactFlowBounds.top,
      });
      const _id = await getBlockId();

      let addelem = "";
      console.log(blockType);
      if(blockType === 'textBlock'){
        console.log('textBlock1111')
        addelem = {
          id: _id,
          type: blockType,
          position,
          editMode: true,
          button: true,
          data: {
            _id,
            type,
            chatbotId,
            meta: {
              position,
            },
          },
          //style: { width: 100, fontSize: 11, color: 'white' },
        }
      }else{
      addelem = {
        id: _id,
        type: blockType,
        position,
        editMode: true,
        data: {
          _id,
          type,
          chatbotId,
          meta: {
            position,
          },
        },
        //style: { width: 100, fontSize: 11, color: 'white' },
      }
      }

      Chatbot.setBlock(addelem);
      console.log("SEEEEE", addelem)
      console.log(Object.values(blocks).length )
      if(Object.values(blocks).length === 0){
        setNodes(addelem)
      } else {
        setNodes(nodes => [...nodes, addelem])
      }
     

      setNode(_id)
      setEditorstate(true)
      setEditElement(<BlockEditor node={_id}/>)

    } catch (error) {
      console.error(error);
    }
  };



  const createCopy = async (event, node) =>{
 
    event.preventDefault();

    
    console.log(node)
    const _id = await getBlockId();
    const thisblockdata = await Chatbot.getBlock(node.id);
    console.log(thisblockdata)
    const newobject = deepClone(thisblockdata);
    newobject["position"]["x"] += 200
    newobject["data"]["_id"] = _id
    newobject.editMode = true
    newobject.id = _id
    if(newobject.data && newobject.data.variable){
    newobject.data.variable = newobject.data.variable + "_copy"
    }

    console.log(newobject)
    Chatbot.setBlock(newobject);

    setNew(true) 

    console.log(Object.values(blocks).length )
    if(Object.values(blocks).length === 0){
      setNodes(newobject)
    } else {
      setNodes(nodes => [...nodes, newobject])
    }

    
  /*   const response = await Chatbot.setBlockData(newobject.id, {}, true);
    console.log(response) */
   


    setTimeout(() => {
      setNode(_id) 
      setEditorstate(true)
      setEditElement(<BlockEditor node={_id}/>)
  
    }, "10")


  /*   let block = "";
    block = _find(chatbot.blocks, {editMode: true}); 

    console.log(block) */

   /*  const response = await Chatbot.setBlockData(_id, {}, true);
    console.log(response) */

   // saveBlock();


  }
  function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}



/* Create copies into database */
const createBlocksCopies = async (data) =>{
  
  /* using let handle as obj, because handle will be the object name in meta data of handle obj, otherwise handle as object name is not needed */

  console.log(data)

  for (let key in data) {
      let handle = data[key];
      Chatbot.setBlock(handle);

      if(handle.data.type === "handle"){
       const _id = handle.id
       const response = Chatbot.setBlockData(
        _id,
        {
          _id: handle.id,
          type: 'handle',
          chatbotId: chatbot._id,
          meta: {handle},
        },
        true,
      );
      console.log(response)
     } else {
      const response = await Chatbot.setBlockData(handle.id, {}, true);
      setNodes(nodes => [...nodes, handle])
      console.log(response)
    }
    
     
}
setTimeout(() => {

  reactFlowInstance.fitView()

}, "200")




}
const updateIDsAndReferences = async (data) =>  {
  console.log(chatbotId)
  let newbotid = chatbotId;
  let idMap = {};

  // Create mapping of old IDs to new IDs
  for (let key in data) {

      idMap[key] = await getBlockId();
  }

  // Replace object keys and IDs with new IDs
  let updatedData = {};
  for (let key in data) {
      let newObjKey = idMap[key];
      updatedData[newObjKey] = { ...data[key], id: newObjKey};
      // If the data object has an _id field, update that as well
      if (updatedData[newObjKey].data && updatedData[newObjKey].data._id) {
          updatedData[newObjKey].data._id = newObjKey;
      }
      if (updatedData[newObjKey].data && updatedData[newObjKey].data.chatbotId) {
        updatedData[newObjKey].data.chatbotId = newbotid;
    }
  }

  // Update references
  for (let key in updatedData) {
      let obj = updatedData[key];
      if (obj.data) {
          if (obj.data.next) obj.data.next = idMap[obj.data.next] || obj.data.next;
          if (obj.data.nextOnTrue) obj.data.nextOnTrue = idMap[obj.data.nextOnTrue] || obj.data.nextOnTrue;
          if (obj.data.nextOnFalse) obj.data.nextOnFalse = idMap[obj.data.nextOnFalse] || obj.data.nextOnFalse;
          if (obj.data.next_secondary) obj.data.next_secondary = idMap[obj.data.next_secondary] || obj.data.next_secondary;
          if (obj.data.options) {
              for (let optionKey in obj.data.options) {
                  if (obj.data.options[optionKey].next) {
                      obj.data.options[optionKey].next = idMap[obj.data.options[optionKey].next] || obj.data.options[optionKey].next;
                  }
              }
          }
          if (obj.data.reviews) {
              for (let reviewKey in obj.data.reviews) {
                  if (obj.data.reviews[reviewKey].element && obj.data.reviews[reviewKey].element.id) {
                      obj.data.reviews[reviewKey].element.id = idMap[obj.data.reviews[reviewKey].element.id] || obj.data.reviews[reviewKey].element.id;
                      obj.data.reviews[reviewKey].element.value = idMap[obj.data.reviews[reviewKey].element.value] || obj.data.reviews[reviewKey].element.value;
                      obj.data.reviews[reviewKey].element.content = idMap[obj.data.reviews[reviewKey].element.content] || obj.data.reviews[reviewKey].element.content;
                  }
              }
          }
      }
      if (obj.source) obj.source = idMap[obj.source] || obj.source;
      if (obj.target) obj.target = idMap[obj.target] || obj.target;
  }

  console.log(updatedData)
  return updatedData;
}

const importNodes = async (nodedata) => {
     console.log(nodedata)
     const deepcopy = await deepClone(nodedata)
     const updated = await updateIDsAndReferences(deepcopy);
     await createBlocksCopies(updated);
}



/*IN future if import json data to blocks is needed  */



 const fileInputRef = useRef(null);
 const [jsonData, setJsonData] = useState(null);
 const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
          try {
              const json = JSON.parse(e.target.result);
              //setJsonData(json);
              importNodes(json)
              console.log(json)
          } catch (error) {
              console.error("Error parsing JSON:", error);
              alert("Invalid JSON file.");
          }
      };
      reader.readAsText(file);
  }
 };



 useEffect(() => {
      document.addEventListener('keydown', handleKeyDown);

  return () => {
      document.removeEventListener('keydown', handleKeyDown);
  };
}, []);

const handleKeyDown = (event) => {
  if (event.ctrlKey || event.metaKey) {
      switch (event.key) {
          case 'c':
              console.log(window.blockstocopy)
              window.blockss = window.blockstocopy
              setShowConfirmation(true);
              setNotificationDialog(<NotificationDialog show={showConfirmation} message="Copied blocks" type="noti"/>)
              break;

          case 'v':
              console.log(window.blockss)
              importNodes(window.blockss)
              break;
          default:
              break;
      }
  }
};



 // const isNodeSelected = (id) => selectedNodes.some(node => node.id === id);

  const onClick = async (event, node) => {

    /* Group select if ctrl down. Still some issus when selecting and deselecting. */
    if (event.ctrlKey || event.metaKey) {


   //   if (!isNodeSelected(node.id)) {

        console.log("not")
        const nodedata = _find(chatbot.blocks, {id: node.id}); 
        console.log(nodedata)
        let deepclone = {[nodedata.id]: nodedata}
      // const nodedata = await Chatbot.getBlock(node.id);
      // const deepclone = deepClone(nodedata);
       //const deepclone2 = {[deepclone.id]: deepclone}

        console.log(deepclone)

          setSelectedNodes(prevSelected => ({...prevSelected, ...deepclone}));          
          document.getElementById(node.id).classList.add("nodeaction");

     // } else {
        //  setSelectedNodes((prevSelected) => prevSelected.filter(node => node.id !== node.id));
        //  document.getElementById(node.id).classList.remove("nodeaction");
     // }


      setTimeout(() => {

        console.log(selectedNodes)
        
      }, "100")
      
    } else {
    
  


    setShowConfirmation(false);
   
    //if(nodeToDelete !== null) handleCancelDelete()

    /* Handle Save. Save only if there have been changes. For better performance.
    This is controlled using window object and defining window.edited in onchange event inside input or other component. */
    if(isedited || isnew){
      const save = await saveBlock(node); 
      if(save.error) return null;
    }
    
    /* This is to prevent onClick event happening again.
    This can be changed to use local useState manager for active node. */
    if(window.el !== node.id) return null;
    window.el = "";


    setEditElement();

    // Setting clicked block editmode true:
    //Chatbot.setBlock({id: node.id, editMode: true});
  

    setTimeout(() => {

      /* Can I remove setnode, is setEditelemet and editorstate both needed? */

      setNode(node.id) 
      setEditorstate(true)
      setEditElement(<BlockEditor node={node.id}/>)
      window.isEdited = false;
      setEdited(false)
  
    }, "10")



  }
   
  };

  function downloadJSON(data, name) {
    // Prompt for filename
    console.log(name)
    let filename = "";
    if(name){
       filename = name;
    }else{
      filename = prompt("Enter filename:", "data.json");
    }
    if (!filename) return;  // If user cancels the prompt, exit the function

    // Convert the data to a JSON string
    const jsonString = JSON.stringify(data, null, 2);  // 2 spaces for indentation

    // Create a Blob from the JSON string
    const blob = new Blob([jsonString], { type: 'application/json' });

    // Create a link element and trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename.endsWith('.json') ? filename : `${filename}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

  function clearnodeaction () {

    // Get all elements with the class name 'your-classname'
    
    const node = document.getElementsByClassName('node');
    console.log(node.length)
       // Loop through each element and remove the class 'class-to-remove'
       for (let i = 0; i < node.length; i++) {
        node[i].classList.remove('nodeaction');
    }
    

 
  }


  const onPaneClick = async (node) => {
    
    /* clear nodeaction */
    if(Object.keys(selectedNodes).length > 0){
    clearnodeaction();
    }
    setSelectedNodes([]);






    setShowConfirmation(false);
 
    //if(nodeToDelete !== null) handleCancelDelete()
    
    window.progress = false;

    /* console.log(isedited, isnew) */
    if(isedited || isnew){
      const save = await saveBlock(); 
      if(save.error) return null;
    }

    setEditorstate(false);

  };



const saveBlock = async () =>  {

    

    let block = "";
    if(isnew){
      console.log("new")
       block = _find(chatbot.blocks, {editMode: true});
    }else{
      console.log("old")
       block = _find(chatbot.blocks, {id: isnode})
    }
    console.log("SAVING", block)


    if (block) {


      /* When delete second route from editor - delete edge that is connected to it */
      if(block.type === 'inputBlock' && block.data.secondbutton === "" && block.data.next_secondary !== ""){

        /* should update node edges but something is wrong. FIX */
        const newEdges = edges.filter((edge) => edge.sourceHandle !== 'next_secondary' && edge.source !== block.data._id);
        const edgeToDelete = edges.filter((edge) => edge.sourceHandle === 'next_secondary' && edge.source === block.data._id);
        Chatbot.setBlockData(block.id, {next_secondary : ""})
        Chatbot.removeBlock(edgeToDelete[0].id, true);
      }
      
      console.log("SAV", block.id)
      const response = await Chatbot.setBlockData(block.id, {}, true);
      console.log(response)
      if(response.type === 'input'){console.log('INPUT BLOCK')}
      console.log(response)


      if (!response.error) {
       /*  if(response.error){
          let {error, message} = response;
          const tovalidate = Object.keys(error);
          window.validate = tovalidate;
          window.validationError = true;
          
          validateInputs(tovalidate)
          setShowConfirmation(true);
          setNotificationDialog(<NotificationDialog show={showConfirmation} message="Fill out the required fields" type="error"/>)
          setNew(false)

          return {
            error: true,
          }
        } */
       /* NO ERROR */
        Chatbot.setBlock({id: block.id, editMode: false});
        setNew(false)
        //setEdited(false)
        return {
          error: false,
        }
      } else {

        let {error, message} = response;
        const tovalidate = Object.keys(error);
        console.log("KEYS TO VALIDATE:" + tovalidate)
        window.validate = tovalidate;
        window.validationError = true;
        
        validateInputs(tovalidate)
        
        setShowConfirmation(true);
        setNotificationDialog(<NotificationDialog show={showConfirmation} message="Fill out the required fields" type="error"/>)
        setNew(true)
        //window.nError({title: 'Need to fill out required fields', text: message, icon: 'fa-exclamation-triangle', error});

        return {
          error: true,
        }
      }
    }else{
      return {
        error: false,
      }
    }
  }


  const getBlockId = async () => {
    let {id} = await axios.get('/block/id');
    //error checking
    return id;
  };

  const initFlow = async (instance) => {
    setReactFlowInstance(instance)
    setTimeout(() => instance.fitView(), 100)
  }
/*   const customKeyHandler = (event) => {
    if (event.code === 'Backspace') {
      // Prevent backspace and delete key events
      event.preventDefault();
      return false;
    }
  
    return true;
  }; */

  return (
    <>

      <div className="x_panel chatbot-builder">



            <div className="header-menu d-flex justify-content-between">
            

              <div className='btn-add' onClick={()=> {setSidebarstate(sidebarstate ? false : true, sidebarstate ? true : false)}}>
                {sidebarstate ? '-' : '+'}
              </div>

              

              <h2>
                Chat Builder: <b>{name}</b>
              </h2>
             


              <div className="d-flex justify-content-between">
                <div className='btn-switch' onClick={() => { setPreview(preview ? false : true)}}>
                  <div  
                    className={`switch ${preview ? 'on' : 'off'}`}>
                    <span></span>
                  </div>
                  <span className='text'>Preview</span>
                </div>

                <div>
                  <input 
                      type="file" 
                      ref={fileInputRef} 
                      onChange={handleFileChange} 
                      accept=".json"
                      style={{ display: 'none' }}
                  />
                  
                   <button  className="btn-add link" onClick={() => fileInputRef.current.click()}>I</button>
                 </div>

                {Object.keys(selectedNodes).length > 0 && (
                <button
                  className="btn-add link"
                  onClick={() => {
                    downloadJSON(selectedNodes);
                  }}
                >
                  download
                </button> 
                )}

                <button
                  className="btn-add link"
                  onClick={() => {
                    window.open(`/chatbot-variables/${chatbotId}`, '', `width=600,height=${window.screen.height}`);
                  }}
                >
                  Variables
                </button> 

                <a href={`${window.appurl+window.appid}`} target="_blank">
                      <div className='btn-add link'>
                          TEST 
                      </div>
                  </a>
                
                 <button
                  className="btn-add link"
                  onClick={() => {
                    downloadJSON(chatbot.blocks, name);
                  }}
                >
                  d
                </button> 
                 
             </div>


                {/* {User.isAdmin() ? (
                  <button className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modalPublish">
                    Publish
                  </button>
                ) : (
                  <button className="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#modalSubmit">
                    Submit for approval
                  </button>
                )}*/}

              
              

             
            </div>


            <div className="x_content">
              {/* <ChatPreview /> */}
              {/* <Chat /> */}
              <div className="row h-100">
                
                 {sidebarstate && (
                  <div className="addcontent">
                  <Sidebar />
                  </div>
                  )}
              
                <div className="col-12 p-0" ref={reactFlowWrapper} >
                  <CustomConfirmation />

                  <ReactFlow 
                   // onInit={(instance) => {setReactFlowInstance(instance)}}
                   // onInit={(instance) => setTimeout(() => instance.fitView(), 100)}
                     onInit={initFlow}
                    //onload={onLoad}
                    //elementsSelectable={true}
                   // keyDown={customKeyHandler}
                    
                    //disable delete key
                    //deleteKeyCode={null}
                   
                    nodes = {nodes}
                    edges = {edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}

                    onConnect={onConnect}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    onNodeDragStop={onNodeDragStop}

                    //onNodeDoubleClick={onNodeDoubleClick}   HILJEM
                   
                   // zoomTo(node)
                    /* onMove={handleMove} */

                    onNodeClick={onClick}
                   // onNodeDoubleClick={onDoubleClick}
                  
                  
                  
                  
                  
                    onNodeContextMenu={createCopy}
                  
                  
                  
                   // onEdgeClick={onEdgeClick}
                    onEdgesDelete={storeEdges}
                    //onNodesDelete={nodeDelete}

                    onSelectionChange={handleSelectionChange}

                    snapToGrid={true}
                    nodeTypes={nodeTypes}

                    /* deleteKeyCode={46} */
                    onPaneClick={onPaneClick}
                    minZoom={0.1}


                    style={graphStyles}
                    >


                    <Controls />
                    <Background gap={20} />
                  </ReactFlow>
                </div>

                {showConfirmation && notificationDialog}
               


                {editorstate && (
                <div className="node-editor">



                    {isnode !== undefined && (
                      <>
                      {editelement}
                      </>
                     )}
              
                </div>
                )}

              </div>
            </div>
      </div>
     

    {/*   <div
        className="modal fade"
        id="modalSubmit"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <Form url="/chatbot/submit" initialValues={{chatbotId}}>
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="staticBackdropLabel">
                  Submit
                </h6>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="description" className="form-label d-none">
                    Description
                  </label>
                  <Field
                    as="textarea"
                    id="description"
                    name="description"
                    className="form-control"
                    placeholder="This bot deals with all the required employment contracts"
                    rows="6"
                  />
                  <div className="form-text">
                    Write a small description about your chatbot, (also if you apply for changes then mention what has
                    changed)
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">
                  Close
                </button>
                <button type="submit" className="btn btn-primary btn-sm">
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div> */}




      {/* PROBLEEM, AGA SEE EI OLE KA KASUTUSEL
      
          <div
            className="modal fade"
            id="modalPublish"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title" id="staticBackdropLabel">
                    Publish this Chatbot
                  </h6>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label d-none">
                      Chatbot Script Tag
                    </label>
                    <textarea
                      id="publishCode"
                      name="publishCode"
                      className="form-control"
                      placeholder=""
                      rows="12"
                      value={`<link href="https://dev-admin.hugo.legal/widget/css/chat-widget.css" rel="stylesheet" />


                      <script>
                        var chatbotId = '${chatbotId}';
                        var lang = 'en';
                      </script>
                      <script src="https://dev-admin.hugo.legal/widget/js/runtime.js"></script>
                      <script src="https://dev-admin.hugo.legal/widget/js/chunk.js"></script>
                      <script src="https://dev-admin.hugo.legal/widget/js/main.js"></script>`}
                    />
                    <div className="form-text">Add this code to your website's "head" tag</div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
      */}
    </>
  );
  
});
