import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {Handle} from 'reactflow';
//import {Handle} from 'react-flow-renderer';
import {Form} from '../';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onSuccess, onEdit, onSetStart, onRemove, onClose, isLightColor} from '../../helpers/block-helper';
import {Link} from 'react-router-dom';
import {IntNodeComponent, ButtonState, InputComponent, TextareaComponent, SelectComp} from './Components'



export const ProjectBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
 
 


  if (!block) {
    return null;
  }
  const {data} = block;

  return (
    <>
        <IntNodeComponent name="Project" icon="project" data={data} node={node} start={start}/>
    </>
  );
});

export const ProjectEditor = ({nodeid, block}) => {
  

 
  const {data} = block;

  if (!block) {
    return null;
  }

  const variableOptions = Chatbot.getBlockVariables();

  let status= [
    {
      label: 'waiting',
      value: 'waiting',
      content: 'waiting',
    },
    {
      label: 'done',
      value: 'done',
      content: 'done',
    },
  ];


  let deadline= [
    {
      label: '1 day',
      value: '1',
      content: '1',
    },
    {
      label: '2 days',
      value: '2',
      content: '2',
    },
    {
      label: '3 days',
      value: '3',
      content: '3',
    },
  ];

  let task_billed= [
    {
      label: 'Billed',
      value: true,
      content: true,
    },
    {
      label: 'Not billed',
      value: false,
      content: false,
    },
  ];

  const handleBlur = (e) => {   
    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
  };
    function setproject(){
      Chatbot.setBlockData(block.id, {action: 'project'})
  
    }
    function settask(){
      Chatbot.setBlockData(block.id, {action: 'task'})
    }


return (
  <>
         
         <div className='tab-container'>
                   <div className={`tab btn-section ${data.action === "project" ? 'active' : ''}`} onClick={() => setproject()}>
                      <span></span>
                      Project
                    </div>
                    <div className={`tab btn-section ${data.action === "task" ? 'active' : ''}`} onClick={() => settask()}>
                      <span></span>
                      Task
                    </div>
         </div>

                    {data.action === 'project' && (
                      <>
                        <SelectComp data={data} name="Project Name" dataname="project_name" placeholder="Select" options={variableOptions} block={block} value="label" />
                        <SelectComp data={data} name="Project Description" dataname="project_description" placeholder="Select" options={variableOptions} block={block} value="label" />
                        <SelectComp data={data} name="Project Files" dataname="project_files" placeholder="Select" options={variableOptions} block={block} value="label" />

                        <SelectComp data={data} name="Project Owner" dataname="project_owner" placeholder="Select" options={variableOptions} block={block} value="label" />
                      </>
                                                        
                          
                    )}


                    { data.action === 'task' && (  

                      <>
                            <SelectComp data={data} name="Task" dataname="task_name" placeholder="Select" options={variableOptions} block={block} value="label" />
                            <SelectComp data={data} name="Task Duration in minutes" dataname="task_duration" placeholder="Select" options={variableOptions} block={block} value="label" />
                            <SelectComp data={data} name="Status" dataname="status" placeholder="Select" options={status} block={block} value="label" />
                            <SelectComp data={data} name="Deadline (days)" dataname="deadline" placeholder="Select" options={deadline} block={block} value="label" />

                            <ButtonState block={block} data={data} startstate={false} dataname="task_billed" label="BILLED"/>
                            


                      </>


                    )} 
              
            
              
{/*               <TextareaComponent data={data} name="Prompt" dataname="aiprompt" onBlur={handleBlur} rows="16"/>
 */}            
            
             {/*  <SelectComp data={data} name="Project Name" dataname="project_name" placeholder="Select" options={variableOptions} block={block} value="label" />
 */}
          

            {/*   <SelectComp data={data} name="Prepayment no/ invoice no" dataname="prepayment_no" placeholder="Select" options={variableOptions} block={block} value="label" />

 */}
  
              <i>Preconditions: invoice_no, client, lawyer? </i>
         
  </>
  

)
};