import React, { useEffect, useState } from 'react';
import { DataTablePaginated } from '../components'; // Adjust the path if necessary
import { axios } from '../libs/axios';
import ReactPaginate from 'react-paginate';

export const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(100); // Default to 100 items per page
  const [month, setMonth] = useState('');
  const [year, setYear] = useState(new Date().getFullYear());
  const [tableType, setTableType] = useState('simple'); // 'simple' or 'complex'

  // Tooltip state for project name hover
  const [tooltip, setTooltip] = useState({
    visible: false,
    text: '',
    x: 0,
    y: 0,
  });

  const handleTooltipShow = (e, text) => {
    setTooltip({
      visible: true,
      text,
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleTooltipMove = (e) => {
    setTooltip((prev) => ({
      ...prev,
      x: e.clientX,
      y: e.clientY,
    }));
  };

  const handleTooltipHide = () => {
    setTooltip((prev) => ({ ...prev, visible: false }));
  };

  // Fetch projects
  const fetchProjects = async (page = 1, sizePerPage = 50, month = '', year = '') => {
    try {
      setLoading(true);
      const response = await axios.get('/projects', {
        params: { page, sizePerPage, month, year },
      });

      setProjects(response.projects);
      setTotalRecords(response.total);
      setSelectedProjects([]); // Clear selection on fetch
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects(currentPage, sizePerPage, month, year);
  }, [currentPage, sizePerPage, month, year]);

  const handleFilterChange = () => {
    setCurrentPage(1);
    fetchProjects(1, sizePerPage, month, year);
  };

  // --- Bulk Selection Handlers ---
  const handleSelectRow = (projectId) => {
    setSelectedProjects((prevSelected) => {
      if (prevSelected.includes(projectId)) {
        return prevSelected.filter((id) => id !== projectId);
      } else {
        return [...prevSelected, projectId];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectedProjects((prevSelected) => {
      if (prevSelected.length === projects.length) {
        return [];
      } else {
        return projects.map((p) => p._id);
      }
    });
  };

  // Bulk delete
  const handleBulkDelete = async () => {
    if (!window.confirm('Are you sure you want to delete the selected projects?')) return;
    try {
      await axios.delete('/projects/bulk', { data: { projectIds: selectedProjects } });
      fetchProjects(currentPage, sizePerPage, month, year);
      setSelectedProjects([]);
    } catch (error) {
      console.error('Error deleting projects:', error);
    }
  };

  // Example tasks/prepayment handlers
  const onAddTask = ({ _id, project_no, client_email }) => {
    addTask(_id, project_no, client_email);
  };

  const onRowClick = ({ project_no}) => {
    window.location.href = `/projects/${project_no}`;
  };


  const addTask = async (id, no, client_email, project_name) => {
    const url =
      'http://localhost:3001/?bot=678fa154b5c5cf673821069c&project_no=' +
      id +
      '&clientemail=' +
      client_email +
      '&action=task' +
      '&service=' +
      project_name;
    window.open(url, '_blank', 'width=400,height=800,resizable,scrollbars');
  };

  const onPrepayment = ({ _id, project_no, client_email, project_name }) => {
    addPrepayment(_id, project_no, client_email, project_name);
  };

  const addPrepayment = async (id, no, client_email, project_name) => {
    const url =
      'http://localhost:3001/?bot=678fa154b5c5cf673821069c&project_no=' +
      id +
      '&client_email=' +
      client_email +
      '&action=prepayment' +
      '&service=' +
      project_name;
    window.open(url, '_blank', 'width=400,height=800,resizable,scrollbars');
  };

  // Helper for status icon in simple table
  const renderStatusIcon = (status) => {
    // Map statuses to colors
    let color = 'gray';
    if (status === 'active') color = 'green';
    if (status === 'waiting') color = 'yellow';
    if (status === 'finished') color = 'gray';

    return (
      <div
        style={{
          width: '12px',
          height: '12px',
          borderRadius: '50%',
          backgroundColor: color,
          margin: '0 auto',
        }}
      />
    );
  };

  // --- Simple Table Columns ---
  const simpleColumns = [
    {
      dataField: 'status',
      text: '',
      headerStyle: { width: '30px' },
      formatter: (cell, row) => renderStatusIcon(row.status),
    },
    {
      dataField: 'deadline_info',
      text: 'Tähtaeg',
      formatter: (cell, row) => {
        const taskWithDeadline = row.related_tasks.find((task) => task.deadline);
        if (taskWithDeadline && taskWithDeadline.deadline) {
          return new Date(taskWithDeadline.deadline).toLocaleDateString();
        }
        return '';
      },
    },
    {
      dataField: 'project_name',
      text: 'Projekti nimi',
      formatter: (cell, row) => (
        <span
          onMouseEnter={(e) => handleTooltipShow(e, row.project_description)}
          onMouseMove={handleTooltipMove}
          onMouseLeave={handleTooltipHide}
        >
          {row.project_name}
        </span>
      ),
    },
    {
      dataField: 'client_name',
      text: 'Klient',
    },
    {
      dataField: 'project_owner',
      text: 'Omanik',
    },
    {
      dataField: 'project_owner_id',
      text: 'Omaniku CRM id',
    },
    {
      dataField: 'total_hours',
      text: 'Kokku tundi',
      formatter: (cell, row) => {
        if (row.related_tasks && Array.isArray(row.related_tasks)) {
          const totalMinutes = row.related_tasks.reduce((sum, task) => {
            return sum + (parseFloat(task.task_duration) || 0);
          }, 0);
          const totalHours = (totalMinutes / 60).toFixed(2);
          return totalHours;
        }
        return 0;
      },
    },
    {
      dataField: 'createdAt',
      text: 'Loodud',
      formatter: (date) => new Date(date).toLocaleDateString(),
    },
    {
      dataField: 'view',
      text: 'Vaata',
      formatter: (cell, row) => (
        <a
          href={`/projects/${row.project_no}`}
          style={{
            border: '1px solid #E9EAF3',
            backgroundColor: '#F3F4F8',
            color: '#032097',
            padding: '5px 10px',
            borderRadius: '5px',
            textDecoration: 'none',
          }}
          onClick={(e) => {
            // Prevent row click propagation if the button is clicked
            e.stopPropagation();
          }}
        >
          Vaata
        </a>
      ),
    },
  ];

  // --- Complex Table Columns ---
  const complexColumns = [
    {
      dataField: 'select',
      text: '',
      isDummyField: true,
      headerFormatter: () => (
        <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={projects.length > 0 && selectedProjects.length === projects.length}
        />
      ),
      formatter: (cell, row) => (
        <input
          type="checkbox"
          checked={selectedProjects.includes(row._id)}
          onChange={() => handleSelectRow(row._id)}
          onClick={(e) => e.stopPropagation()}
        />
      ),
    },
    {
      dataField: 'project_no',
      text: 'Projekti nr',
    },
    {
      dataField: 'status',
      text: '',
      headerStyle: { width: '30px' },
      formatter: (cell, row) => {
        const statusColors = {
          active: 'green',
          waiting: 'yellow',
          finished: 'gray',
        };
        return (
          <span style={{ color: statusColors[row.status] || 'gray', fontWeight: 'bold' }}>
            {row.status}
          </span>
        );
      },
    },
    {
      dataField: 'deadline_info',
      text: 'Tähtaeg',
      formatter: (cell, row) => {
        const taskWithDeadline = row.related_tasks.find((task) => task.deadline);
        if (taskWithDeadline && taskWithDeadline.deadline) {
          return new Date(taskWithDeadline.deadline).toLocaleDateString();
        }
        return '';
      },
    },
    { dataField: 'client_name', text: 'Klient' },
    { dataField: 'client_email', text: 'Kliendi e-post' },
    { dataField: 'related_client_id', text: 'Kliendi ID' },
    {
      dataField: 'project_name',
      text: 'Projekti nimi',
      formatter: (cell, row) => (
        <span
          onMouseEnter={(e) => handleTooltipShow(e, row.project_description)}
          onMouseMove={handleTooltipMove}
          onMouseLeave={handleTooltipHide}
        >
          {row.project_name}
        </span>
      ),
    },
    { dataField: 'project_owner', text: 'Omanik' },
    { dataField: 'prepayment_amount', text: 'Ettemaksed' },
    {
      dataField: 'total_hours',
      text: 'Kokku tundi',
      formatter: (cell, row) => {
        if (row.related_tasks && Array.isArray(row.related_tasks)) {
          const totalMinutes = row.related_tasks.reduce((sum, task) => {
            return sum + (parseFloat(task.task_duration) || 0);
          }, 0);
          const totalHours = (totalMinutes / 60).toFixed(2);
          return totalHours;
        }
        return 0;
      },
    },
    {
      dataField: 'createdAt',
      text: 'Loodud',
      formatter: (date) => new Date(date).toLocaleDateString(),
    },
    {
      dataField: 'view',
      text: 'Vaata',
      formatter: (cell, row) => (
        <a
          href={`/projects/${row.project_no}`}
          style={{
            border: '1px solid #E9EAF3',
            backgroundColor: '#F3F4F8',
            color: '#032097',
            padding: '5px 10px',
            borderRadius: '5px',
            textDecoration: 'none',
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Vaata
        </a>
      ),
    },
  ];

  // Toggle between "simple" and "complex" table
  const toggleTableType = () => {
    setTableType((prev) => (prev === 'simple' ? 'complex' : 'simple'));
  };

  // Row style function for clickable rows
  const getRowStyle = (row) => {
    if (row.status === 'waiting') {
      return {
        backgroundColor: '#F3FFF8',
        borderBottom: '2px solid #2DCA73',
        color: '#485184',
        cursor: 'pointer',
      };
    }
    return {
      backgroundColor: '#F9FAFC',
      color: '#555F7E',
      cursor: 'pointer',
    };
  };

  return (
    <div className="row table_v2">
      <div className="col p-0">
        <div className="x_panel chatbot-builder">
          <div className="header-menu d-flex justify-content-between">
            <h2>Projektid</h2>
            <div>
              <button onClick={toggleTableType} style={{ marginRight: '10px' }}>
                {tableType === 'simple' ? 'Rohkem' : 'Vähem'}
              </button>

              {/* Hide the Delete Selected button in simple mode */}
              {tableType === 'complex' && (
                <button
                  onClick={handleBulkDelete}
                  disabled={selectedProjects.length === 0}
                  style={{ marginRight: '10px' }}
                >
                  Kustuta valitud
                </button>
              )}

              <label htmlFor="pageSize">Elemente lehel: </label>
              <select
                id="pageSize"
                value={sizePerPage}
                onChange={(e) => {
                  const newSizePerPage = parseInt(e.target.value, 10);
                  setSizePerPage(newSizePerPage);
                  setCurrentPage(1);
                  fetchProjects(1, newSizePerPage, month, year);
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>

              <select value={month} onChange={(e) => setMonth(e.target.value)}>
                <option value="">Kõik kuud</option>
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {new Date(0, i).toLocaleString('default', { month: 'long' })}
                  </option>
                ))}
              </select>

              <select value={year} onChange={(e) => setYear(e.target.value)}>
                {Array.from({ length: 10 }, (_, i) => {
                  const yearOption = new Date().getFullYear() - i;
                  return (
                    <option key={yearOption} value={yearOption}>
                      {yearOption}
                    </option>
                  );
                })}
              </select>

              <button onClick={handleFilterChange}>Filtreeri</button>
            </div>
          </div>
          <div className="x_content">
            <div className="page-container">
              {loading ? (
                <div>Laadimine...</div>
              ) : (
                <>
                  <DataTablePaginated
                    data={projects}
                    columns={tableType === 'simple' ? simpleColumns : complexColumns}
                    onPrepayment={onPrepayment}
                    onAddTask={onAddTask}
                    rowStyle={(row, rowIndex) => getRowStyle(row)}
                    onRowClick={onRowClick}
                    rowClassName={() => 'table-row'}
                  />
                  <ReactPaginate
                    previousLabel={'Eelmine'}
                    nextLabel={'Järgmine'}
                    breakLabel={'...'}
                    pageCount={Math.ceil(totalRecords / sizePerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(e) => {
                      const newPage = e.selected + 1;
                      setCurrentPage(newPage);
                      fetchProjects(newPage, sizePerPage, month, year);
                    }}
                    forcePage={currentPage - 1}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Tooltip */}
      {tooltip.visible && (
        <div
          style={{
            position: 'fixed',
            top: tooltip.y + 10,
            left: tooltip.x + 10,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            width: '250px',
            padding: '15px',
            borderRadius: '3px',
            boxShadow: '0 0 5px rgba(0,0,0,0.2)',
            zIndex: 1000,
          }}
        >
          {tooltip.text}
        </div>
      )}
      {/* Inline style for row hover */}
      <style>{`
        .table-row:hover {
          background-color: #FFFFFF !important;
        }
      `}</style>
    </div>
  );
};
