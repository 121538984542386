import Cookies from 'js-cookie';
import {base_url} from '../config';
import _axios from 'axios';
const {nError, NProgress, localStorage} = window;
class Axios {
  constructor() {
    this.service = _axios.create({
      baseURL: base_url,
    });
    this.service.interceptors.request.use(
      (config) => {
        NProgress.start();
        config.headers.Authorization = `Bearer ${this._getJWT()}`;
        return config;
      },
      (error) => {
        NProgress.done();
        return Promise.reject(error);
      },
    );
    this.service.interceptors.response.use(
      (response) => {
        NProgress.done();
        return response;
      },
      (error) => {
        NProgress.done();
        return Promise.reject(error);
      },
    );
  }

  async post(url, payload) {
    try {
      console.log("Doing something", url, payload);

      const {data} = await this.service.post(url, payload);
      return data;
    } catch (error) {
      return this._handleErrors(error);
    }
  }

  async put(url, payload) {
    console.log(url, payload)
    try {
      const {data} = await this.service.put(url, payload);
      return data;
    } catch (error) {
      return this._handleErrors(error);
    }
  }

  async saveBot(chatbotId, payload) {
    try {
      console.log("Save BOT")
      const {data} = await this.service.post(`chatbot/saveBot/${chatbotId}`, payload);
      return data;
    } catch (error) {
      return this._handleErrors(error);
    }
  }


  async get(url, payload) {
    try {
      const {data} = await this.service.get(url, payload);
      return data;
    } catch (error) {
      return this._handleErrors(error);
    }
  }

  async delete(url, payload) {
    console.log(url, payload)
    try {
      //Used here const {data} = await this.service.delete(url, payload); so would delete invoice, and projects? but did not delete blocks anymore..
      const {data} = await this.service.delete(url, {data: payload});
      return data;
    } catch (error) {
      return this._handleErrors(error);
    }
  }

  async patch(url, payload) {
    try {
      const { data } = await this.service.patch(url, payload);
      return data;
    } catch (error) {
      return this._handleErrors(error);
    }
  }

  _handleErrors(error) {
    const {status} = error.response;
    switch (status) {
      case 422: {
        return {
          status,
          ...error.response?.data,
        };
      }
      case 404: {
        return {
          status,
          message: error.message,
          error: {},
        };
      }
      case 409: {
        return {
          status,
          ...error.response?.data,
        };
      }
      case 403: {
        return {
          status,
          ...error.response?.data,
        };
      }
      case 401: {
        nError({
          title: 'Unauthorized',
          text: 'Your session has been expired, Please login again',
          icon: 'fa-exclamation-triangle',
        });
        return {
          status,
          ...error.response?.data,
        };
      }
      default: {
        nError({title: 'Critical Error', text: error, icon: 'fa-exclamation-triangle'});
        return {
          status,
          ...error.response?.data,
        };
      }
    }
  }

  _getJWT() {
    if (localStorage) {
      const jwt = localStorage.getItem('sid');
      if (jwt) {
        return jwt;
      } else {
        const jwt = Cookies.get('sid');
        if (jwt) {
          localStorage.setItem('sid', jwt);
          Cookies.remove('sid');
        }
        return jwt;
      }
    }
  }
}
export const axios = new Axios();
