import { projectSlice } from '../slices';
import store from '../../store';
import { axios } from '../../libs';
const { nError } = window;

const Actions = projectSlice.actions;
const { dispatch, getState } = store;

const set = (data) => {
  dispatch(Actions.set(data));
};

const get = () => {
  return (state) => state.projects;
};

const fetchProjects = async () => {
  try {
    const { projects } = await axios.get('/project');
    dispatch(Actions.set({ projects }));
    return projects;
  } catch (error) {
    nError({ title: 'Error fetching projects' });
    return false;
  }
};

export const Project = {
  set,
  get,
  fetchProjects,
};