import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { axios } from '../libs/axios';
import { DataTablePaginated } from '../components';

export const Project = () => {
  const { projectNo } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  // Owner – fetched user object (includes partner_vat Boolean and partner_hourly_rate)
  const [owner, setOwner] = useState(null);

  // Invoices
  const [prepayments, setPrepayments] = useState([]);
  const [invoices, setInvoices] = useState([]);

  // Other modals remain unchanged…
  const [showPrepaymentModal, setShowPrepaymentModal] = useState(false);
  const [showGenerateInvoiceModal, setShowGenerateInvoiceModal] = useState(false);
  const [showInvoiceConfirmation, setShowInvoiceConfirmation] = useState(false);
  const [showFinishProjectModal, setShowFinishProjectModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);

  // -------------------- Unified Add/Edit Task Modal State --------------------
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [isEditingTask, setIsEditingTask] = useState(false);
  const [taskModalData, setTaskModalData] = useState(null);
  const [taskModalErrors, setTaskModalErrors] = useState({});
  const [taskDetails, setTaskDetails] = useState({});

  // Comment state
  const [commentText, setCommentText] = useState('');
  const [commentExpanded, setCommentExpanded] = useState(false);

  // Add Prepayment form – using a dropdown for minutes
  const [prepaymentHours, setPrepaymentHours] = useState('');
  const [prepaymentComment, setPrepaymentComment] = useState('');
  const [showPrepaymentConfirmation, setShowPrepaymentConfirmation] = useState(false);
  const [prepaymentFeedback, setPrepaymentFeedback] = useState('');
  const [isPrepaymentProcessing, setIsPrepaymentProcessing] = useState(false);

  // Generate Invoice info and processing states
  const [unbilledTasksTotal, setUnbilledTasksTotal] = useState(0);
  const [paidPrepaymentsTotal, setPaidPrepaymentsTotal] = useState(0);
  const [finalInvoiceAmount, setFinalInvoiceAmount] = useState(0);
  const [invoiceFeedback, setInvoiceFeedback] = useState('');
  const [isInvoiceProcessing, setIsInvoiceProcessing] = useState(false);

  // Tooltip for finishing task without date
  const [finishTooltip, setFinishTooltip] = useState({ visible: false, x: 0, y: 0, message: "" });

  // Accordion logic for Description
  const [descExpanded, setDescExpanded] = useState(false);

  // -------------------- Helper Functions --------------------

  // Refresh project details (and thereby task list, comment etc.)
  const refreshProject = async () => {
    try {
      const response = await axios.get(`/projects/${projectNo}`);
      setProject(response);

      // Also refresh invoices
      const invoiceResponse = await axios.get(`/invoices/project/${response._id}`);
      const invoicesArray = Array.isArray(invoiceResponse) ? invoiceResponse : [];
      const prepaymentInvoices = invoicesArray.filter((inv) => inv.type === 'prepayment');
      const regularInvoices = invoicesArray.filter((inv) => inv.type === 'invoice');
      setPrepayments(prepaymentInvoices);
      setInvoices(regularInvoices);

      // Refresh owner details
      const user_no = response.project_owner_id;
      const relatedUser = await axios.get(`/user/no/${user_no}`);
      setOwner(relatedUser);
    } catch (error) {
      console.error('Error refreshing project:', error);
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch of project and related data
  useEffect(() => {
    refreshProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectNo]);

  if (loading) return <div>Loading...</div>;
  if (!project) return <div>Project not found.</div>;

  // -------------------- Duration Options & Formatter --------------------
  // Generate dropdown options for duration in 10-min intervals (10min to 5 hours)
  const durationOptions = [];
  for (let minutes = 10; minutes <= 300; minutes += 10) {
    const value = minutes; // storing minutes directly
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const display = `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
    durationOptions.push({ value, display });
  }

  // Format minutes into "HH:MM" format for display
  const formatDuration = (minutes) => {
    if (!minutes || isNaN(minutes)) return '';
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
  };

  // -------------------- Project Status Logic --------------------
  const isProjectActive = project.status === 'active';
  const isProjectFinished = project.status === 'finished';
  const isProjectWaiting = project.status === 'waiting';

  // -------------------- Paid Date Formatter --------------------
  const paidDateFormatter = (cell, row) => {
    if (row.status === 'paid' && row.receipt_date) {
      return new Date(row.receipt_date).toLocaleDateString();
    }
    return '';
  };

  // -------------------- Generate Invoice Button Logic --------------------
  const canGenerateInvoice =
    isProjectActive &&
    project.related_tasks.some((task) => !task.task_billed) &&
    !project.related_tasks.some((task) => task.status === 'waiting');

  // -------------------- Finish Project Logic --------------------
  const canFinishProject =
    isProjectActive &&
    project.related_tasks.length > 0 &&
    project.related_tasks.every((task) => task.task_billed) &&
    !project.related_tasks.some((task) => task.status === 'waiting');

  // -------------------- Table Column Definitions --------------------

  // Prepayments Table Columns
  const prepaymentInvoiceColumns = [
    {
      dataField: '_id',
      text: 'ID',
      hidden: true,
    },
    { dataField: 'invoiceNumber', text: 'No' },
    { dataField: 'amount_total', text: 'Total Amount' },
    { dataField: 'vat', text: 'VAT' },
    { dataField: 'amount_net', text: 'Net Amount' },
    { dataField: 'status', text: 'Status' },
    {
      dataField: 'billed',
      text: 'Billed',
      formatter: (cell) => (cell ? 'Yes' : 'No'),
    },
    {
      dataField: 'receipt_date',
      text: 'Paid Date',
      formatter: paidDateFormatter,
    },
    {
      dataField: 'issue_date',
      text: 'Issue Date',
      formatter: (date) => (date ? new Date(date).toLocaleDateString() : ''),
    },
    {
      dataField: 'createdAt',
      text: 'Created Date',
      formatter: (date) => (date ? new Date(date).toLocaleDateString() : ''),
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell, row) => {
        if (row.type === 'prepayment' && row.status === 'draft') {
          return (
            <button className="main-btn" onClick={() => handleSendPrepayment(row)}>
              Send
            </button>
          );
        }
        return null;
      },
    },
  ];

  // Regular Invoices Table Columns
  const invoiceColumns = [
    { dataField: 'invoiceNumber', text: 'No' },
    { dataField: 'amount_total', text: 'Total Amount' },
    { dataField: 'vat', text: 'VAT' },
    { dataField: 'amount_net', text: 'Net Amount' },
    { dataField: 'status', text: 'Status' },
    {
      dataField: 'receipt_date',
      text: 'Paid Date',
      formatter: paidDateFormatter,
    },
    {
      dataField: 'createdAt',
      text: 'Issued Date',
      formatter: (date) => new Date(date).toLocaleDateString(),
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell, row) => {
        if (row.type === 'invoice' && row.status === 'draft') {
          return (
            <button className="main-btn" onClick={() => handleSendInvoice(row)}>
              Send
            </button>
          );
        }
        return null;
      },
    },
  ];

  // Tasks Table Columns – using field “task_name” and formatting duration (from minutes)
  const taskColumns = [
    { dataField: 'task_name', text: 'Task' },
    {
      dataField: 'task_date',
      text: 'Date',
      formatter: (cell) => {
        if (!cell) return '';
        const date = new Date(cell);
        return date.toLocaleDateString('en-GB'); // Formats to DD:MM:YYYY
      }
    },
    {
      dataField: 'task_duration',
      text: 'Duration',
      formatter: (cell) => formatDuration(parseFloat(cell)),
    },
    { dataField: 'status', text: 'Status' },
    {
      dataField: 'task_billed',
      text: 'Billed',
      formatter: (cell) => (cell ? 'Yes' : 'No'),
    },
    {
      dataField: 'related_invoice',
      text: 'Invoice No',
      formatter: (cell) => cell || '',
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: (cell, row) => {
        const buttons = [];
        if (isProjectActive && !row.task_billed && row.status === "waiting" || isProjectActive && row.task_billed && row.status === "waiting") {
          buttons.push(
            <button className="btn-crm-light" onClick={() => handleEditTaskClick(row)}>
              Muuda
            </button>
          );
        }
        if (isProjectActive && row.status === 'waiting') {
          buttons.push(
            <button className="btn-crm-light" onClick={(e) => handleFinishTask(row, e)}>
              Lõpeta tegevus
            </button>
          );
        }
        return (
          <>
            {buttons.map((btn, index) => (
              <span key={index} style={{ marginRight: '5px' }}>
                {btn}
              </span>
            ))}
          </>
        );
      },
    },
  ];

  // -------------------- Unified Task Modal Functions --------------------
  const openAddTaskModal = () => {
    setTaskModalData({
      task_name: '',
      task_duration: '',
      status: 'done',
      deadline: '',
      task_date: ''
    });
    setIsEditingTask(false);
    setTaskModalErrors({});
    setShowTaskModal(true);
  };

  const handleEditTaskClick = (taskItem) => {
    setTaskModalData({
      ...taskItem,
      originalRef: taskItem,
      deadline: taskItem.deadline ? new Date(taskItem.deadline).toISOString().split('T')[0] : '',
      task_date: taskItem.task_date ? new Date(taskItem.task_date).toISOString().split('T')[0] : ''
    });
    setIsEditingTask(true);
    setTaskModalErrors({});
    setShowTaskModal(true);
  };

  const handleSaveTaskModal = async () => {
    // Validate mandatory fields
    let errors = {};
    if (!taskModalData.task_name.trim()) errors.task_name = "Task name is required";
    if (!taskModalData.task_duration) errors.task_duration = "Duration is required";
    if (taskModalData.status === 'done' && !taskModalData.task_date)
      errors.task_date = "Date is required when task state is done";

    if (Object.keys(errors).length > 0) {
      setTaskModalErrors(errors);
      return;
    }

    if (isEditingTask) {
      const updatedTasks = project.related_tasks.map((t) => {
        if (t === taskModalData.originalRef) {
          return {
            ...t,
            task_name: taskModalData.task_name,
            task_duration: taskModalData.task_duration,
            status: taskModalData.status,
            deadline: taskModalData.deadline ? new Date(taskModalData.deadline) : null,
            task_date: taskModalData.task_date ? new Date(taskModalData.task_date) : null
          };
        }
        return t;
      });

      try {
        await axios.patch(`/projects/${project._id}`, {
          related_tasks: updatedTasks,
        });

        setProject((prev) => ({
          ...prev,
          related_tasks: updatedTasks,
        }));

        setShowTaskModal(false);
        setTaskModalData(null);
        setTaskModalErrors({});
      } catch (error) {
        console.error('Error saving edited task:', error);
      }
    } else {
      const newTask = {
        task_name: taskModalData.task_name,
        task_duration: parseFloat(taskModalData.task_duration) || 0,
        status: taskModalData.status,
        deadline: taskModalData.deadline ? new Date(taskModalData.deadline) : null,
        task_date: taskModalData.task_date ? new Date(taskModalData.task_date) : null,
        task_billed: false,
      };

      const updatedTasks = [...project.related_tasks, newTask];

      try {
        await axios.patch(`/projects/${project._id}`, {
          related_tasks: updatedTasks,
        });

        setProject((prev) => ({
          ...prev,
          related_tasks: updatedTasks,
        }));

        setShowTaskModal(false);
        setTaskModalData(null);
        setTaskModalErrors({});
      } catch (error) {
        console.error('Error adding task:', error);
      }
    }
  };

  // -------------------- Finish Task Function --------------------
  const handleFinishTask = async (taskItem, event) => {
    if (!taskItem.task_date) {
      setFinishTooltip({
        visible: true,
        x: event.clientX,
        y: event.clientY,
        message: "Please set a date for the task before finishing it."
      });
      setTimeout(() => setFinishTooltip({ visible: false, x: 0, y: 0, message: "" }), 3000);
      return;
    }
    try {
      const updatedTasks = project.related_tasks.map((t) => {
        if (t === taskItem) {
          return { ...t, status: 'done' };
        }
        return t;
      });
      await axios.patch(`/projects/${project._id}`, {
        related_tasks: updatedTasks,
      });
      setProject((prev) => ({ ...prev, related_tasks: updatedTasks }));
    } catch (error) {
      console.error('Error finishing task:', error);
    }
  };

  // -------------------- Prepayment Functions --------------------
  const onGeneratePrepayment = () => setShowPrepaymentModal(true);

  const getVatPercentage = () => (owner && owner.partner_vat ? 0.22 : 0);

  const confirmPrepayment = async () => {
    try {
      setIsPrepaymentProcessing(true);
      const hoursNum = parseFloat(prepaymentHours) || 0;
      const hourlyRate = parseFloat(owner.partner_hourly_rate) || 0;
      const totalAmount = ((hoursNum / 60) * hourlyRate).toFixed(2);
      const vatPercentage = getVatPercentage();

      const invoiceData = {
        projectId: project._id,
        projectNo: project.project_no,
        clientEmail: project.client_email,
        clientName: project.client_name,
        projectName: project.project_name,
        amount: totalAmount,
        clientId: project.related_client_id,
        partner_id: project.project_owner_no,
      };

      const response = await generatePrepayment(invoiceData, vatPercentage);
      if (!response.invoiceNumber) {
        console.warn("Error: Prepayment generation failed");
        setPrepaymentFeedback("Error generating prepayment in system.");
        return;
      }
      const invoiceNumber = response.invoiceNumber;
      setPrepaymentFeedback(`Prepayment ${invoiceNumber} created in draft state. Sending email...`);

      const botId = '67cc2842c7a21d8e097693d7';
      const comment = prepaymentComment;
      const paymentLink = `https://app.hugo.legal/hugoapp?bot=${botId}&project_no=${invoiceData.projectNo}&service=${encodeURIComponent(
        invoiceData.projectName
      )}&comment=${encodeURIComponent(comment)}&selected_service_price=${invoiceData.amount}&invoice_no=${invoiceNumber}`;

      const sendpayment = await axios.post(`/projects/${project._id}/sendPrepayment`, {
        email: project.client_email,
        client: project.client_name,
        amount: response.amount_total,
        comment,
        invoice_no: invoiceNumber,
        owner: project.project_owner,
        partner_hourly_rate: owner.partner_hourly_rate,
        partner_name: owner.partner_name,
        partner_company: owner.partner_company_name,
        partner_company_reg_id: owner.partner_company_reg_id,
        partner_company_address: owner.partner_company_address,
        vat: response.vat,
        link: paymentLink,
      });

      if (sendpayment.error) {
        setPrepaymentFeedback(`Prepayment ${invoiceNumber} EMAIL NOT SENT`);
        const invoiceResponse = await axios.get(`/invoices/project/${project._id}`);
        const prepaymentInvoices = Array.isArray(invoiceResponse)
          ? invoiceResponse.filter((inv) => inv.type === 'prepayment')
          : [];
        setPrepayments(prepaymentInvoices);
        return;
      }

      const issue_date = new Date();
      const due_date = new Date(issue_date.getTime() + 7 * 24 * 60 * 60 * 1000);

      await axios.put(`/invoices/${response._id}`, {
        status: 'sent',
        issue_date,
        due_date,
      });

      const invoiceResponse = await axios.get(`/invoices/project/${project._id}`);
      const updatedPrepayments = Array.isArray(invoiceResponse)
        ? invoiceResponse.filter((inv) => inv.type === 'prepayment')
        : [];
      setPrepayments(updatedPrepayments);

      setPrepaymentFeedback(`Prepayment ${invoiceNumber} email sent and updated successfully.`);
    } catch (error) {
      console.error('Error generating prepayment:', error);
      setPrepaymentFeedback("Error generating prepayment: " + error.message);
    } finally {
      setIsPrepaymentProcessing(false);
    }
  };

  const handleSendPrepayment = async (invoice) => {
    try {
      setIsPrepaymentProcessing(true);
      const botId = '67cc2842c7a21d8e097693d7';
      const comment = prepaymentComment || '';
      const paymentLink = `https://app.hugo.legal/hugoapp?bot=${botId}&project_no=${project.project_no}&service=${encodeURIComponent(
        project.project_name
      )}&comment=${encodeURIComponent(comment)}&selected_service_price=${invoice.amount_total}&invoice_no=${invoice.invoiceNumber}`;

      const sendpayment = await axios.post(`/projects/${project._id}/sendPrepayment`, {
        email: project.client_email,
        client: project.client_name,
        amount: invoice.amount_total,
        comment,
        invoice_no: invoice.invoiceNumber,

        owner: project.project_owner,
        partner_hourly_rate: owner.partner_hourly_rate,
        partner_name: owner.partner_name,
        partner_company: owner.partner_company_name,
        partner_company_reg_id: owner.partner_company_reg_id,
        partner_company_address: owner.partner_company_address,
        vat: invoice.vat,
        link: paymentLink,
   
      });

      if (sendpayment.error) {
        setPrepaymentFeedback(`Prepayment EMAIL NOT SENT`);
        const invoiceResponse = await axios.get(`/invoices/project/${project._id}`);
        const prepaymentInvoices = Array.isArray(invoiceResponse)
          ? invoiceResponse.filter((inv) => inv.type === 'prepayment')
          : [];
        setPrepayments(prepaymentInvoices);
        return;
      }

      const issue_date = new Date();
      const due_date = new Date(issue_date.getTime() + 7 * 24 * 60 * 60 * 1000);
      await axios.put(`/invoices/${invoice._id}`, {
        status: 'sent',
        issue_date,
        due_date,
      });

      setPrepaymentFeedback(`Prepayment ${invoice.invoiceNumber} email sent and updated successfully.`);
      const invoiceResponse = await axios.get(`/invoices/project/${project._id}`);
      const updatedPrepayments = Array.isArray(invoiceResponse)
        ? invoiceResponse.filter((inv) => inv.type === 'prepayment')
        : [];
      setPrepayments(updatedPrepayments);
    } catch (error) {
      console.error("Error sending prepayment email:", error);
      setPrepaymentFeedback("Error sending prepayment email: " + error.message);
    } finally {
      setIsPrepaymentProcessing(false);
    }
  };

  const generatePrepayment = async (invoiceData, vatPercentage) => {
    const response = await axios.post('/invoices/create', {
      related_project_id: invoiceData.projectId,
      related_project_no: invoiceData.projectNo,
      client_id: invoiceData.clientId,
      client_name: invoiceData.clientName,
      client_email: invoiceData.clientEmail,
      amount_total: invoiceData.amount,
      amount_net: (invoiceData.amount / (1 + vatPercentage)).toFixed(2),
      vat: (invoiceData.amount - invoiceData.amount / (1 + vatPercentage)).toFixed(2),
      type: 'prepayment',
      related_partner_id: owner.user_no,
      partner_company_name: owner.partner_company_name,
      partner_name: owner.firstName + ' ' + owner.lastName,
      service: invoiceData.projectName,
      status: 'draft',
    });
    console.log('Prepayment invoice generated:', response);
    return response;
  };

  // -------------------- Invoice Generation --------------------
  const onGenerateInvoice = () => {
    const unbilledTasks = project.related_tasks.filter((t) => !t.task_billed);
    const tasksTotal = unbilledTasks.reduce((sum, t) => {
      const minutes = parseFloat(t.task_duration) || 0;
      return sum + (minutes / 60) * owner.partner_hourly_rate;
    }, 0);

    const unbilledPrepayments = prepayments.filter((p) => p.status === 'paid' && !p.billed);
    const prepaymentsTotal = unbilledPrepayments.reduce((sum, p) => sum + (p.amount_total || 0), 0);

    const invoiceAmount = (tasksTotal - prepaymentsTotal).toFixed(2);

    setUnbilledTasksTotal(tasksTotal.toFixed(2));
    setPaidPrepaymentsTotal(prepaymentsTotal);
    setFinalInvoiceAmount(invoiceAmount > 0 ? invoiceAmount : 0);
    setShowGenerateInvoiceModal(true);
  };

  const handleConfirmGenerateInvoice = async () => {
    try {
      setIsInvoiceProcessing(true);
      if (finalInvoiceAmount <= 0) {
        alert("No invoice needed, prepayments cover all unbilled tasks.");
        setShowGenerateInvoiceModal(false);
        return;
      }
      const unbilledTasks = project.related_tasks.filter((t) => !t.task_billed);
      const tasksDetails = unbilledTasks.map((taskItem) => {
        const minutes = parseFloat(taskItem.task_duration) || 0;
        const hours = (minutes / 60).toFixed(2); // Ensure 2 decimal places
        const cost = (hours * owner.partner_hourly_rate).toFixed(2); // Ensure 2 decimal places
        return {
          taskName: taskItem.task_name,
          date: taskItem.task_date,
          hours,
          cost,
        };
      });
      const vatPercentage = getVatPercentage();

      const response = await axios.post('/invoices/create', {
        client_name: project.client_name,
        client_email: project.client_email,
        service: project.project_name,
        amount_total: finalInvoiceAmount,
        amount_net: (finalInvoiceAmount / (1 + vatPercentage)).toFixed(2),
        vat: (finalInvoiceAmount - finalInvoiceAmount / (1 + vatPercentage)).toFixed(2),
        type: 'invoice',
        related_project_id: project._id,
        related_project_no: project.project_no,
        client_id: project.related_client_id,

        related_partner_id: owner.user_no,
        partner_company_name: owner.partner_company_name,
        partner_name: owner.firstName + ' ' + owner.lastName,

        tasks: tasksDetails, // ???
        status: 'draft',
      });
      const invoiceNumber = response.invoice?.invoiceNumber || response.invoiceNumber;
      if (!invoiceNumber) {
        alert("Error generating invoice in system.");
        setShowGenerateInvoiceModal(false);
        return;
      }

      const updatedUnbilledTasks = unbilledTasks.map((taskItem) => {
        const minutes = parseFloat(taskItem.task_duration) || 0;
        const hours = minutes / 60;
        return {
          ...taskItem,
          task_billed: true,
          billed_amount: hours * owner.partner_hourly_rate,  // i dont think its in the system
          related_invoice: invoiceNumber,
        };
      });
      const updatedAllTasks = project.related_tasks.map((t) => {
        const updated = updatedUnbilledTasks.find((u) => u.task_name === t.task_name);
        return updated || t;
      });

      await axios.patch(`/projects/${project._id}`, {
        related_tasks: updatedAllTasks,
      });
      setProject((prev) => ({ ...prev, related_tasks: updatedAllTasks }));

      const unbilledPrepayments = prepayments.filter((p) => p.status === 'paid' && !p.billed);
      await Promise.all(
        unbilledPrepayments.map((p) =>
          axios.put(`/invoices/${p._id}`, {
            billed: true,
          })
        )
      );
      const invoiceResponse = await axios.get(`/invoices/project/${project._id}`);
      const updatedPrepayments = Array.isArray(invoiceResponse)
        ? invoiceResponse.filter((inv) => inv.type === 'prepayment')
        : [];
      setPrepayments(updatedPrepayments);


      const botId = '67cc2842c7a21d8e097693d7';
      const paymentLink = `https://app.hugo.legal/hugoapp?bot=${botId}&project_no=${project.project_no}&service=${encodeURIComponent(
        project.project_name
      )}&selected_service_price=${finalInvoiceAmount}&invoice_no=${invoiceNumber}`;

      const sendInvoice = await axios.post(`/projects/${project._id}/sendInvoice`, {
        email: project.client_email,
        client: project.client_name,
        amount: finalInvoiceAmount,
        prepayments_amount: paidPrepaymentsTotal,
        service: project.project_name,
        invoice_no: invoiceNumber,
        owner: project.project_owner,
        partner_name: owner.partner_name,
        partner_company: owner.partner_company_name,
        partner_company_reg_id: owner.partner_company_reg_id,
        partner_company_address: owner.partner_company_address,
        partner_hourly_rate: owner.partner_hourly_rate,
        link: paymentLink,
        tasksDetails: tasksDetails,
        vat: finalInvoiceAmount - finalInvoiceAmount / (1 + vatPercentage),
      });

      if (sendInvoice.error) {
        setInvoiceFeedback(`Invoice ${invoiceNumber} EMAIL NOT SENT`);
        return;
      }

      const issue_date = new Date();
      const due_date = new Date(issue_date.getTime() + 7 * 24 * 60 * 60 * 1000);
      await axios.put(`/invoices/${response._id}`, {
        status: 'sent',
        issue_date,
        due_date,
      });

      const updatedInvoiceResponse = await axios.get(`/invoices/project/${project._id}`);
      const regularInvoices = Array.isArray(updatedInvoiceResponse)
        ? updatedInvoiceResponse.filter((inv) => inv.type === 'invoice')
        : [];
      setInvoices(regularInvoices);

      setInvoiceFeedback(`Invoice ${invoiceNumber} email sent and updated successfully.`);
      alert("Invoice generated successfully!");
    } catch (error) {
      console.error('Error generating invoice:', error);
      alert("Error generating invoice");
    } finally {
      setIsInvoiceProcessing(false);
      setShowGenerateInvoiceModal(false);
    }
  };

  const handleSendInvoice = async (invoice) => {
    try {
      setIsInvoiceProcessing(true);
      const relatedTasks = project.related_tasks.filter((taskItem) => taskItem.related_invoice === invoice.invoiceNumber);
      const tasksDetails = relatedTasks.map((taskItem) => {
        const minutes = parseFloat(taskItem.task_duration) || 0;
        const hours = (minutes / 60).toFixed(2); // Ensure 2 decimal places
        const cost = (parseFloat(hours) * owner.partner_hourly_rate).toFixed(2); // Ensure 2 decimal places
        return {
          taskName: taskItem.task_name,
          date: taskItem.task_date,
          hours,
          cost,
        };
      });

      const botId = '67cc2842c7a21d8e097693d7';
      const comment = prepaymentComment || '';
      const paymentLink = `https://app.hugo.legal/hugoapp?bot=${botId}&project_no=${project.project_no}&service=${encodeURIComponent(
        project.project_name
      )}&comment=${encodeURIComponent(comment)}&selected_service_price=${invoice.amount_total}&invoice_no=${invoice.invoiceNumber}`;

   

      const sendInvoice = await axios.post(`/projects/${project._id}/sendInvoice`, {
        email: project.client_email,
        client: project.client_name,
        amount: invoice.amount_total.toFixed(2),
        prepayments_amount: paidPrepaymentsTotal,
        service: project.project_name,
        invoice_no: invoice.invoiceNumber,
        owner: project.project_owner,
        partner_hourly_rate: owner.partner_hourly_rate,
        partner_name: owner.partner_name,
        partner_company: owner.partner_company_name,
        partner_company_reg_id: owner.partner_company_reg_id,
        partner_company_address: owner.partner_company_address,
        link: paymentLink,
        tasksDetails: tasksDetails,
        vat: invoice.vat,
      });

      if (sendInvoice.error) {
        setInvoiceFeedback(`Invoice ${invoice.invoiceNumber} EMAIL NOT SENT`);
        const invoiceResponse = await axios.get(`/invoices/project/${project._id}`);
        const regularInvoices = Array.isArray(invoiceResponse)
          ? invoiceResponse.filter((inv) => inv.type === 'invoice')
          : [];
        setInvoices(regularInvoices);
        return;
      }

      const issue_date = new Date();
      const due_date = new Date(issue_date.getTime() + 7 * 24 * 60 * 60 * 1000);
      await axios.put(`/invoices/${invoice._id}`, {
        status: 'sent',
        issue_date,
        due_date,
      });

      setInvoiceFeedback(`Invoice ${invoice.invoiceNumber} email sent and updated successfully.`);
      const invoiceResponse = await axios.get(`/invoices/project/${project._id}`);
      const regularInvoices = Array.isArray(invoiceResponse)
        ? invoiceResponse.filter((inv) => inv.type === 'invoice')
        : [];
      setInvoices(regularInvoices);
    } catch (error) {
      console.error("Error sending invoice email:", error);
      setInvoiceFeedback("Error sending invoice email: " + error.message);
    } finally {
      setIsInvoiceProcessing(false);
    }
  };

  // -------------------- Finish Project & Set Active --------------------
  const onFinishProject = () => setShowFinishProjectModal(true);

  const handleFinishProject = async () => {
    try {
  
      await axios.post(`/projects/${project._id}/finish`, {
        project_owner_email: owner.email,
      });

      setProject((prev) => ({ ...prev, status: 'finished' }));
      alert("Project finished and email sent to client.");
    } catch (error) {
      console.error('Error finishing project:', error);
      alert("Error finishing project");
    } finally {
      setShowFinishProjectModal(false);
    }
  };

  const handleSetActive = async () => {
    try {
      await axios.patch(`/projects/${project._id}`, { status: 'active' });
      refreshProject();
    } catch (error) {
      console.error('Error setting project active:', error);
    }
  };

  // Helper for status icon in simple table
  const renderStatusIcon = (status) => {
    // Map statuses to colors
    let color = 'gray';
    if (status === 'active') color = 'green';
    if (status === 'waiting') color = 'yellow';
    if (status === 'finished') color = 'gray';

    return (
      <div
        style={{
          width: '12px',
          height: '12px',
          borderRadius: '50%',
          backgroundColor: color,
          margin: '0 auto',
          position: 'relative',
          display: 'inline-block'
        }}
      />
    );
  };


  // -------------------- Description Accordion --------------------
  const maxChars = 400;
  const description = project.project_description || '';
  const truncatedDesc =
    description.length > maxChars ? description.substring(0, maxChars) + '...' : description;

  // -------------------- RENDER --------------------
  return (
    <div className="row table_v2">
      <div className="col p-0">
        <div className="x_panel chatbot-builder">
          <div className="header-menu d-flex ">
            <h2></h2>
          </div>
          <div className="x_content">
            <div className="page-container">

            <div className="col p-0 mb-3">

               
                  {isProjectActive && (
                    <>
                       <button onClick={openAddTaskModal} className="btn-crm-light" >
                        + Lisa tegevus
                      </button>
                      <button onClick={onGeneratePrepayment} className="btn-crm-light" style={{ marginLeft: '10px' }}>
                        + Küsi ettemaks
                      </button>                              
                      <button
                        onClick={onGenerateInvoice}
                        disabled={!canGenerateInvoice}
                        className={`btn-crm-light ${!canGenerateInvoice ? 'inactive' : ''}`}
                        style={{ marginLeft: '10px' }}
                      >
                        + Loo müügiarve
                      </button>


                      
                    </>
                  )}

            </div>


            <div className="col p-0 ">
                 <div className="p-0 col-8 project-container mr-5 mb-5">

                 <div className="col p-0 justify-content-between">
                   <div className='p-0 col-8'>
                      <h2>
                         {renderStatusIcon(project.status)}{project.project_name}
                        </h2>
                    </div>
                    <div className='p-0 col-1'>
                     #{project.project_no}
                    </div>
                  </div>
                  <p>
                    <span style={{ color: '#555F7E',fontSize: '14px' }}>Kliendi kirjeldus: </span>{' '}
                    <br/>
                    {descExpanded ? description : truncatedDesc}{' '}
                    
                    {description.length > maxChars && (
                      <span className='link2' style={{ marginLeft: '10px' }}  onClick={() => setDescExpanded(!descExpanded)}>
                        {descExpanded ? '<<<<' : 'Loe edasi'}
                      </span>
                    )}
                  </p>
                  <p>
                  <span style={{ color: '#555F7E',fontSize: '14px' }}>Failid: </span>{' '}

                    <a href={project.project_files} target="_blank" rel="noopener noreferrer">
                      {project.project_files}
                    </a>
                  </p>
                  {project.comment && (
                    <p>
                      <span style={{ color: '#555F7E',fontSize: '14px' }}>Kommentaar: </span>{' '}
                      <br/>
                      {commentExpanded
                        ? project.comment
                        : project.comment.length > maxChars
                        ? project.comment.substring(0, maxChars) + '...'
                        : project.comment}
                      {project.comment.length > maxChars && (
                        <button style={{ marginLeft: '10px' }} onClick={() => setCommentExpanded(!commentExpanded)}>
                          {commentExpanded ? 'Show less' : 'Show more'}
                        </button>
                      )}
                    </p>
                  )}


                    
{isProjectActive && (
                    <>
                       
                    <button
                        onClick={() => {
                          setShowCommentModal(true);
                          setCommentText(project.comment || '');
                        }}
                        className="btn-crm-light" 
                      >
                        {project.comment ? 'Sinu kommentaar' : '+ Lisa kommentaar'}
                      </button>
                      </>
                    )}
                
                  </div>
                  
                  <div className="p-0 col-3">


               
                      <p>
                      <span 
                        style={{ color: '#748AA1',fontSize: '12px' }}>Klient: </span>
                          <br/>
                        <span style={{color: '#31394D',fontSize: '16px' }}>{project.client_name}
                          </span>
                      </p>
                      <p>
                      <span 
                        style={{ color: '#748AA1',fontSize: '12px' }}>Email: </span>
                          <br/>
                        <span style={{color: '#31394D',fontSize: '16px' }}>{project.client_email}
                          </span>
                      </p>
                      <p>
                      <span 
                        style={{ color: '#748AA1',fontSize: '12px' }}>Telefon: </span>
                          <br/>
                        <span style={{color: '#31394D',fontSize: '16px' }}>{project.client_phone}
                          </span>
                      </p>

                      <p>
                      <span 
                        style={{ color: '#748AA1',fontSize: '12px' }}>Isikukood: </span>
                          <br/>
                        <span style={{color: '#31394D',fontSize: '16px' }}>{project.client_pid}
                          </span>
                      </p>



                    {/*  
                    Hide project owner info if not admin

                    
                    <p>
                        <strong>Project Owner:</strong> {project.project_owner}
                      </p>
                      <p>
                        <strong>Project Owner ID:</strong> {project.project_owner_id}
                      </p>

                      <p>
                        <strong>Hourly Rate:</strong> {owner && owner.partner_hourly_rate}
                      </p>
                      <p>
                        <strong>Partner Company Name:</strong>{' '}
                        {owner && owner.partner_company_name ? owner.partner_company_name : 'N/A'}
                      </p>
                      <p>
                        <strong>Partner Email:</strong> {owner && owner.email ? owner.email : 'N/A'}
                      </p>
                      <p>
                        <strong>Partner Company Address:</strong>{' '}
                        {owner && owner.partner_company_address ? owner.partner_company_address : 'N/A'}
                      </p> */}
   
   

                  {isProjectWaiting && (
                    <button onClick={handleSetActive} className="main-btn" 
                    
                    >
                     Alusta projektiga
                    </button>
                  )}
                  {isProjectFinished && (
                    <button onClick={handleSetActive} className="main-btn">
                     Taasava
                    </button>
                  )}
   
                 {!isProjectWaiting && (
                    <>

                        <button
                          onClick={onFinishProject}
                          disabled={!canFinishProject}
                          className={`main-btn ${!canFinishProject ? 'inactive' : ''}`}
                          style={{
                            background: !canFinishProject ? '#DADCE9' : '',
                            color: !canFinishProject ? '858EBD' : '',
                            border: !canFinishProject ? 'none' : '',
                          }}
                        >
                          Lõpeta projekt
                        </button>
                      

                    </>
                  )}

                  </div>
            </div>




         
              {project.related_tasks.length > 0 && (
                <>
                  <h4>Tegevused</h4>
                  <DataTablePaginated data={project.related_tasks} columns={taskColumns} />
                </>
              )}
              {prepayments.length > 0 ? (
                <>
                  <h4>Ettemaksud</h4>
                  <DataTablePaginated data={prepayments} columns={prepaymentInvoiceColumns} />
                </>
              ) : (
                <p>No prepayments invoices available.</p>
              )}
              {invoices.length > 0 ? (
                <>
                  <h4>Invoices</h4>
                  <DataTablePaginated data={invoices} columns={invoiceColumns} />
                </>
              ) : (
                <p>No invoices available.</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Tooltip for finishing task without date */}
      {finishTooltip.visible && (
        <div
          style={{
            position: 'fixed',
            left: finishTooltip.x,
            top: finishTooltip.y,
            background: 'rgba(255,0,0,0.8)',
            color: '#fff',
            padding: '5px 10px',
            borderRadius: '3px',
            zIndex: 1000,
            pointerEvents: 'none'
          }}
        >
          {finishTooltip.message}
        </div>
      )}

      {/* -------------------- Unified Add/Edit Task Modal -------------------- */}
      {showTaskModal && taskModalData && (
        <div className="modal">
          <div className="modal-content">
            <span
              className="modalclose"
              onClick={() => {
                setShowTaskModal(false);
                setTaskModalData(null);
                setTaskModalErrors({});
              }}
            >
              &times;
            </span>
            <h3 className="modal_header">{isEditingTask ? "Edit Task" : "Add Task"}</h3>
            <div style={{ marginTop: '20px' }}>
              <label>Task Name</label>
              <textarea
                className={"form-control" + (taskModalErrors.task_name ? " invalid" : "")}
                value={taskModalData.task_name}
                onChange={(e) => setTaskModalData(prev => ({ ...prev, task_name: e.target.value }))}
                style={{ marginBottom: '10px' }}
              />
              {taskModalErrors.task_name && <div style={{ color: 'red' }}>{taskModalErrors.task_name}</div>}
              <label>Duration</label>
              <select
                className={"form-control" + (taskModalErrors.task_duration ? " invalid" : "")}
                value={taskModalData.task_duration}
                onChange={(e) => setTaskModalData(prev => ({ ...prev, task_duration: e.target.value }))}
                style={{ marginBottom: '10px' }}
              >
                <option value="">Select duration</option>
                {durationOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.display}
                  </option>
                ))}
              </select>
              {taskModalErrors.task_duration && <div style={{ color: 'red' }}>{taskModalErrors.task_duration}</div>}
              <label>Status</label>
              <select
                className="form-control"
                value={taskModalData.status}
                onChange={(e) => setTaskModalData(prev => ({ ...prev, status: e.target.value }))}
                style={{ marginBottom: '10px' }}
              >
                <option value="done">done</option>
                <option value="waiting">waiting</option>
              </select>
              <label>Date</label>
              <input
                type="date"
                className={"form-control" + (taskModalErrors.task_date ? " invalid" : "")}
                value={taskModalData.task_date}
                onChange={(e) => setTaskModalData(prev => ({ ...prev, task_date: e.target.value }))}
                style={{ marginBottom: '10px' }}
              />
              {taskModalErrors.task_date && <div style={{ color: 'red' }}>{taskModalErrors.task_date}</div>}
              <label>Deadline</label>
              <input
                type="date"
                className="form-control"
                value={taskModalData.deadline}
                onChange={(e) => setTaskModalData(prev => ({ ...prev, deadline: e.target.value }))}
                style={{ marginBottom: '10px' }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <button onClick={handleSaveTaskModal} className="main-btn" style={{ marginRight: '10px' }}>
                {isEditingTask ? "Save Changes" : "Save Task"}
              </button>
              <button
                onClick={() => {
                  setShowTaskModal(false);
                  setTaskModalData(null);
                  setTaskModalErrors({});
                }}
                className="light-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* -------------------- Add Comment Modal -------------------- */}
      {showCommentModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="modalclose" onClick={() => setShowCommentModal(false)}>
              &times;
            </span>
            <h3 className="modal_header">{project.comment ? "Edit Comment" : "Add Comment"}</h3>
            <div style={{ marginTop: '20px' }}>
              <label>Comment</label>
              <textarea
                className="form-control"
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                style={{ marginBottom: '10px' }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <button
                onClick={async () => {
                  try {
                    await axios.patch(`/projects/${project._id}`, { comment: commentText });
                    refreshProject();
                    setShowCommentModal(false);
                    setCommentText('');
                  } catch (error) {
                    console.error('Error saving comment:', error);
                  }
                }}
                className="main-btn"
                style={{ marginRight: '10px' }}
              >
                Save Comment
              </button>
              <button onClick={() => setShowCommentModal(false)} className="light-btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* -------------------- Add Prepayment Modal -------------------- */}
      {showPrepaymentModal && (
        <div className="modal">
          <div className="modal-content">
            <span
              className="modalclose"
              onClick={() => {
                setShowPrepaymentModal(false);
                setPrepaymentFeedback('');
                setShowPrepaymentConfirmation(false);
              }}
            >
              &times;
            </span>
            <h3 className="modal_header">Add Prepayment</h3>
            <div style={{ marginTop: '20px' }}>
              <p>
                This prepayment will be sent to <strong>{project.client_name}</strong> at{' '}
                <strong>{project.client_email}</strong> as a payment link.
              </p>
              <label>Number of Minutes</label>
              <select
                className="form-control"
                value={prepaymentHours}
                onChange={(e) => setPrepaymentHours(e.target.value)}
                style={{ marginBottom: '10px' }}
              >
                <option value="">Select duration</option>
                {durationOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.display}
                  </option>
                ))}
              </select>
              <label>Comment</label>
              <input
                type="text"
                className="form-control"
                value={prepaymentComment}
                onChange={(e) => setPrepaymentComment(e.target.value)}
                style={{ marginBottom: '10px' }}
              />
              <p>
                <strong>Hourly Rate:</strong> {owner && owner.partner_hourly_rate} <br />
                <strong>Prepayment Amount:</strong>{' '}
                {(((parseFloat(prepaymentHours) || 0) / 60) * (parseFloat(owner?.partner_hourly_rate) || 0)).toFixed(2)}
              </p>
            </div>
            {prepaymentFeedback ? (
              <div style={{ marginTop: '20px', padding: '10px', backgroundColor: '#e0ffe0', border: '1px solid green' }}>
                <p>{prepaymentFeedback}</p>
                <button
                  onClick={() => {
                    setShowPrepaymentModal(false);
                    setPrepaymentFeedback('');
                    setPrepaymentHours('');
                    setPrepaymentComment('');
                    setShowPrepaymentConfirmation(false);
                  }}
                  className="main-btn"
                >
                  Close
                </button>
              </div>
            ) : (
              <>
                {showPrepaymentConfirmation ? (
                  <div style={{ marginTop: '20px', padding: '10px', border: '1px solid #ccc', backgroundColor: '#f9f9f9' }}>
                    <p>Do you want to create and send the prepayment?</p>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <button onClick={confirmPrepayment} className="main-btn" style={{ marginRight: '10px' }} disabled={isPrepaymentProcessing}>
                        Yes
                      </button>
                      <button onClick={() => setShowPrepaymentConfirmation(false)} className="light-btn" disabled={isPrepaymentProcessing}>
                        No
                      </button>
                    </div>
                  </div>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    <button
                      onClick={() => setShowPrepaymentConfirmation(true)}
                      className="main-btn"
                      style={{ marginRight: '10px', backgroundColor: !prepaymentHours || parseFloat(prepaymentHours) <= 0 ? 'gray' : '#032097' }}
                      disabled={!prepaymentHours || parseFloat(prepaymentHours) <= 0}
                    >
                      Create and Send Out
                    </button>
                    <button onClick={() => setShowPrepaymentModal(false)} className="light-btn">
                      Cancel
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}

      {/* -------------------- Generate Invoice Modal -------------------- */}
      {showGenerateInvoiceModal && (
        <div className="modal">
          <div className="modal-content">
            <span
              className="modalclose"
              onClick={() => {
                setShowGenerateInvoiceModal(false);
                setInvoiceFeedback('');
                setShowInvoiceConfirmation(false);
              }}
            >
              &times;
            </span>
            <h3 className="modal_header">Generate Invoice</h3>
            {invoiceFeedback ? (
              <div style={{ marginTop: '20px', padding: '10px', backgroundColor: '#e0ffe0', border: '1px solid green' }}>
                <p>{invoiceFeedback}</p>
                <button
                  onClick={() => {
                    setShowGenerateInvoiceModal(false);
                    setInvoiceFeedback('');
                  }}
                  className="main-btn"
                >
                  Close
                </button>
              </div>
            ) : (
              <>
                {showInvoiceConfirmation ? (
                  <div style={{ marginTop: '20px', padding: '10px', border: '1px solid #ccc', backgroundColor: '#f9f9f9' }}>
                    <p>
                      This invoice will be sent to <strong>{project.client_name}</strong> at{' '}
                      <strong>{project.client_email}</strong>.
                    </p>
                    <p>Do you want to generate and send the invoice?</p>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <button onClick={handleConfirmGenerateInvoice} className="main-btn" style={{ marginRight: '10px' }} disabled={isInvoiceProcessing}>
                        Yes
                      </button>
                      <button onClick={() => setShowInvoiceConfirmation(false)} className="light-btn" disabled={isInvoiceProcessing}>
                        No
                      </button>
                    </div>
                  </div>
                ) : (
                  <div style={{ marginTop: '20px' }}>
                    <p>
                      <strong>Unbilled Tasks Total:</strong> {unbilledTasksTotal}
                    </p>
                    <p>
                      <strong>Unbilled Prepayments Total:</strong>{' '}
                      {prepayments
                        .filter((p) => p.status === 'paid' && !p.billed)
                        .reduce((sum, p) => sum + (p.amount_total || 0), 0)
                        .toFixed(2)}
                    </p>
                    <p>
                      <strong>Final Invoice Amount:</strong> {finalInvoiceAmount}
                    </p>
                    <p>
                      This invoice amount is calculated by subtracting the total of unbilled prepayments from the total
                      of unbilled tasks.
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                      <button
                        onClick={() => setShowInvoiceConfirmation(true)}
                        className="main-btn"
                        style={{ marginRight: '10px', backgroundColor: finalInvoiceAmount > 0 ? '#032097' : 'gray' }}
                        disabled={finalInvoiceAmount <= 0}
                      >
                        Generate and Send Out
                      </button>
                      <button onClick={() => setShowGenerateInvoiceModal(false)} className="light-btn">
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}

      {/* -------------------- Finish Project Modal -------------------- */}
      {showFinishProjectModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="modalclose" onClick={() => setShowFinishProjectModal(false)}>
              &times;
            </span>
            <h3 className="modal_header">Finish Project</h3>
            <div style={{ marginTop: '20px' }}>
              <p>Do you want to finish this project?</p>
              <p>
                Upon finishing, an email will be sent to the client with the following details:
              </p>
             
              
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <button onClick={handleFinishProject} className="main-btn" style={{ marginRight: '10px' }}>
                Finish Project
              </button>
              <button onClick={() => setShowFinishProjectModal(false)} className="light-btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
