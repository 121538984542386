import { createSlice } from '@reduxjs/toolkit';

export const projectSlice = createSlice({
  name: 'projects',
  initialState: {
    projects: [],
    loading: false,
    error: null,
  },
  reducers: {
    set: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export default projectSlice.reducer;
