import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { defaultColumnsOption } from '../config';
/**
 * DataTable Component
 * @param {Array} data - The table data
 * @param {Array} columns - Column definitions
 * @param {Function} onTableChange - Callback for table change events (e.g., sorting)
 * @param {Array} defaultSorted - Default sorting configuration
 * @param {String} keyField - Unique key field for rows
 * @param {String} noDataIndication - Message to show when no data is available
 */
export const DataTablePaginated = ({
  data = [],
  columns = [],
  onTableChange,
  onAddTask,
  onRowClick,
  onPrepayment,
  onGenerateInvoice,
  actions,
  defaultSorted = [{ dataField: 'createdAt', order: 'desc' }],
  keyField = '_id',
  noDataIndication = 'No data found',
  classes,
}) => {

    // Define action column formatter
    const columnDataFormatter = (cell, row, rowIndex, formatExtraData) => {
      return (
        <div className="row align-items-center">
          {onAddTask && <span onClick={() => onAddTask(row)} className="col btn-link-page">Add task</span>}
          {onPrepayment && <span onClick={() => onPrepayment(row)} className="col btn-link-page">Prepayment</span>}


         
        </div>
      );
    };
  
    // Define action column
    const columnFormatter = {
      dataField: '',
      text: 'Actions',
      formatter: columnDataFormatter,
    };


      // Define table row click events
/*   const tableEvents = {
    events: {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        if (!navigateTo) return;
        if (dataKey === 'documents') {
          window.open(`${navigateTo}/${row._id}/edit`, '_blank');
        } else {
          push(`${navigateTo}/${row._id}`);
        }
      },
    },
  }; */


  return (
    <BootstrapTable
      hover
      striped
      keyField={keyField} // Unique identifier for rows
      noDataIndication={noDataIndication} // Show when no data is available
      data={data} // Table data
     
     
     // columns={columns} // Table columns

      columns={columns
        .map((column) => ({
          ...column,
          sort: true, // Ensure sorting is enabled for each column
        }))
        //.concat({ ...actions, ...columnFormatter })
        }

      classes="sheet-table" // Custom class for the table

      rowClasses="table-row"
      defaultSorted={defaultSorted} // Set default sorting configuration
      onTableChange={onTableChange} // Optional: Callback for sorting, filtering, etc.
    />
  );
};
